import { AuthOps } from "..";
import { RequestConfirmEmail, RequestResendConfirmation } from "../../actions";
import { takeAction, AsyncReturnType, putAction } from "../../../util";
import { call } from "redux-saga/effects";

function* handleConfirmEmail(
  ops: AuthOps["signup"],
  action: RequestConfirmEmail
) {
  const response: AsyncReturnType<typeof ops.handleVerificationCode> = yield call(
    ops.handleVerificationCode,
    action.email,
    action.code,
    action.password
  );

  yield putAction({ type: "CONFIRM_EMAIL_RESPONSE", response });
}

function* handleResendConfirmation(
  ops: AuthOps["signup"],
  action: RequestResendConfirmation
) {
  const response: AsyncReturnType<typeof ops.resendVerificationCode> = yield call(
    ops.resendVerificationCode,
    action.email
  );

  yield putAction({ type: "RESEND_CONFIRMATION_RESPONSE", response });
}

export function* confirmEmailWatcher(ops: AuthOps["signup"]) {
  while (true) {
    const action = yield takeAction("REQUEST_CONFIRM_EMAIL");
    yield call(handleConfirmEmail, ops, action);
  }
}

export function* resendConfirmationWatcher(ops: AuthOps["signup"]) {
  while (true) {
    const action = yield takeAction("REQUEST_RESEND_CONFIRMATION");
    yield call(handleResendConfirmation, ops, action);
  }
}
