import { connect } from "../../store/connect";
import { LoginScreen } from "./login-screen";
import { LoginCredentials } from "../../auth/login";
import {
  session as sessionLens,
  loading as loadingLens,
  confirmationUnconfirmed,
  loginErrors,
  confirmationErrors,
} from "../../store/lenses";

export const LoginScreenContainer = connect(
  (state) => ({
    errors: loginErrors(state),
    confirmationErrors: confirmationErrors(state),
    loading: loadingLens(state),
    session: sessionLens(state),
    unconfirmed: confirmationUnconfirmed(state),
  }),
  (dispatch) => ({
    submit: (creds: LoginCredentials) => {
      dispatch({ type: "REQUEST_LOGIN", creds });
    },
    clearResetEmail: () => {
      dispatch({ type: "CLEAR_RESET_EMAIL" });
    },
  })
)(LoginScreen);
