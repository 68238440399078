import { getCredentials } from "./user";
import { AsyncReturnType } from "../util";
import AWS from ".";
import { AWS_ENV } from "../environment";
import { reportError } from "./bugsnag-logger";

export type ZapierPayload = {
  email: string;
  firstName: string;
  lastName: string;
  orgName: string;
  inviteCode: string;
  isDev: boolean;
};

type ZapierResponse = {
  success: boolean;
  invalidEmail?: boolean;
  error?: any;
};

function createLambda(credentials: AsyncReturnType<typeof getCredentials>) {
  AWS.config.update({ credentials });

  return new AWS.Lambda({ region: AWS_ENV.region });
}

export async function operateForOptIn(
  payload: ZapierPayload
): Promise<ZapierResponse> {
  const { email, firstName, lastName, orgName, inviteCode, isDev } = payload;
  const awsCredentials = await getCredentials();
  const lambda = createLambda(awsCredentials);
  const lambdaPayload: ZapierPayload = {
    email,
    firstName,
    lastName,
    orgName,
    inviteCode,
    isDev,
  };
  try {
    // Invoke zapier handler lambda
    const raw = await lambda
      .invoke({
        FunctionName: AWS_ENV.zapierHandlerLambda,
        Payload: JSON.stringify(lambdaPayload),
      })
      .promise();
    return JSON.parse(raw.Payload as string) as ZapierResponse;
  } catch (err) {
    reportError("Error while invoking zapier lambda: ", err);
    return { success: false, error: "Invoking Zapier lambda failed" };
  }
}
