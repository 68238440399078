import { Auth } from "aws-amplify";
import * as Login from "./login";
import { isNil } from "lodash";
import {
  emailIsValid,
  nameIsValid,
  passwordIsValid,
  uuidv4,
  normalizeEmail,
} from "../util";
import { getCurrentCognitoId } from "../api/user";
import { createIdentity } from "../api/identity";
import { createUser } from "../api/user";
import { AppSession, CohortInfo } from "../store/state";
import { getSession } from "./session";
import {
  getIdentityInputForCognitoUser,
  getUserInfoInputForCognitoUser,
  getUpstremCohortId,
} from "./user-info";
import {
  initialGeneratedAppStatsInput,
  initialGeneratedLearnStatsInput,
  initialGeneratedPracticeStatsInput,
  initialPathProgressInput,
} from "../store/state-helpers/user-inputs";
import { reportError, reportWarning } from "../api/bugsnag-logger";

export type SignupCredentials = {
  signup: {
    name: string;
    invitationCode: string;
    firstName: string;
    lastName: string;
  };
  login: Login.LoginCredentials;
  cohort: CohortInfo;
  assignParticipant: boolean;
  urlParticipantId?: string;
  urlSubCohort?: string;
};

type SignupSuccess = {
  status: "SUCCESS";
  email: string;
  password: string;
};

type SignupFailedUnconfirmed = {
  status: "ERROR";
  email: string;
  password: string;
  error: "UNCONFIRMED";
};

type SignupFailed = {
  status: "ERROR";
  error:
    | "INVALID_PASSWORD"
    | "INVALID_EMAIL"
    | "ALREADY_EXISTS"
    | "VALIDATION"
    | "UNKNOWN";
  nameIsValid?: boolean;
  firstNameIsValid?: boolean;
  lastNameIsValid?: boolean;
  emailIsValid?: boolean;
  passwordIsValid?: boolean;
};

export type SignupResponse =
  | SignupSuccess
  | SignupFailed
  | SignupFailedUnconfirmed;

type ConfirmEmailSuccess = {
  status: "SUCCESS";
  newSession: AppSession;
};

type ConfirmEmailError = {
  status: "ERROR";
  invalidCode: boolean;
  invalidCredentials: boolean;
  error: string;
};

export type ConfirmEmailResponse = ConfirmEmailSuccess | ConfirmEmailError;

type ResendConfirmationSuccess = { status: "SUCCESS" };

type ResendConfirmationFailure = { status: "ERROR" };

export type ResendConfirmationResponse =
  | ResendConfirmationFailure
  | ResendConfirmationSuccess;

export async function doSignup(
  creds: SignupCredentials
): Promise<SignupResponse> {
  const {
    signup,
    login,
    cohort,
    assignParticipant,
    urlParticipantId,
    urlSubCohort,
  } = creds;
  const { name, invitationCode, firstName, lastName } = signup;
  const { email: rawEmail, password } = login;
  const { id: cohortId, name: cohortName, path, microsupportEnabled } = cohort;
  const email = normalizeEmail(rawEmail);

  const _nameIsValid = nameIsValid(name);
  const _firstNameIsValid = nameIsValid(firstName);
  const _lastNameIsValid = nameIsValid(lastName);
  const _emailIsValid = emailIsValid(email);
  const _passwordIsValid = passwordIsValid(password);

  const submittable =
    _firstNameIsValid && _emailIsValid && _passwordIsValid && _lastNameIsValid;
  if (submittable) {
    const participantId =
      assignParticipant && urlParticipantId
        ? urlParticipantId
        : assignParticipant
        ? uuidv4()
        : "EMPTY_STRING";

    // First log in to see if they-re unconfirmed
    let loginRes: Login.AuthLoginResponse | undefined = undefined;
    try {
      loginRes = await Login.doLogin({
        type: "EMAIL",
        email,
        password,
        skipUpdatePlatforms: true,
      });
      if (Login.isUnconfirmed(loginRes)) {
        return { status: "ERROR", error: "UNCONFIRMED", email, password };
      }
    } catch (e) {
      // If it's not an error we expect, actually throw
      if (e.code !== "UserNotFoundException") {
        throw e;
      }
    }

    const attributes = {
      name,
      email,
      "custom:cohort": path,
      "custom:cohortId": cohortId,
      "custom:cohortName": cohortName,
      "custom:invitationCode": invitationCode,
      "custom:participantId": !isNil(participantId)
        ? participantId
        : "EMPTY_STRING",
      "custom:platforms": "portal",
      "custom:30dcOptIn": "false",
      "custom:subCohort": !isNil(urlSubCohort) ? urlSubCohort : "",
      "custom:microsupportEnabled": isNil(microsupportEnabled)
        ? ""
        : !!microsupportEnabled
        ? "true"
        : "false",
      "custom:firstName": firstName,
      "custom:lastName": lastName,
    };

    try {
      // Do signup
      await Auth.signUp({
        username: email,
        password,
        attributes,
      });

      return {
        status: "SUCCESS",
        email,
        password,
      };
    } catch (e) {
      if (e.code === "UsernameExistsException") {
        return { status: "ERROR", error: "ALREADY_EXISTS" };
      }
      reportError("Unknown error occurred in sign up", e);
      return {
        status: "ERROR",
        error: "UNKNOWN",
      };
    }
  } else {
    reportWarning("Invalid field accepted in sign up", {
      _nameIsValid,
      _firstNameIsValid,
      _lastNameIsValid,
      _emailIsValid,
      _passwordIsValid,
      name,
      email,
      password: "[Redacted]",
    });
    return {
      status: "ERROR",
      error: "VALIDATION",
      emailIsValid: _emailIsValid,
      passwordIsValid: _passwordIsValid,
      nameIsValid: _nameIsValid,
      firstNameIsValid: _firstNameIsValid,
      lastNameIsValid: _lastNameIsValid,
    };
  }
}

export async function handleVerificationCode(
  email: string,
  code: string,
  password: string
): Promise<ConfirmEmailResponse> {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (e) {
    reportError(`Error checking invitation code ${e}`);
    return {
      status: "ERROR",
      invalidCode: true,
      invalidCredentials: false,
      error: e.message,
    };
  }

  try {
    await Auth.signIn(email, password);
  } catch (e) {
    reportError(`Failed to sign in user ${e}`);
    return {
      status: "ERROR",
      invalidCode: false,
      invalidCredentials: true,
      error: e.message,
    };
  }

  try {
    await createGQLIdentity(email);
  } catch (e) {
    reportError(`Unable to create identity or info`);
    return {
      status: "ERROR",
      invalidCode: false,
      invalidCredentials: false,
      error: "Unable to create user identity or info",
    };
  }

  const response = await getSession();
  if (response.status === "SUCCESS") {
    return { status: "SUCCESS", newSession: response.session };
  }

  return {
    status: "ERROR",
    invalidCode: false,
    invalidCredentials: false,
    error: "Unable to retrieve session",
  };
}

export async function createGQLIdentity(email: string) {
  const cognitoId = await getCurrentCognitoId();
  await createIdentity(email, await getIdentityInputForCognitoUser(cognitoId));

  await createUser(
    { userId: cognitoId, cohort: await getUpstremCohortId() },
    {
      appActionHistory: [],
      generatedAppStats: initialGeneratedAppStatsInput(),
      generatedLearnStats: initialGeneratedLearnStatsInput(),
      generatedPracticeStats: initialGeneratedPracticeStatsInput(),
      learnActionHistory: [],
      migrationReceipts: [],
      pathProgress: initialPathProgressInput(),
      practiceActionHistory: [],
      reflections: [],
      surveyHistory: [],
      userInfo: await getUserInfoInputForCognitoUser(),
    }
  );
}

export async function resendVerificationCode(
  username: string
): Promise<ResendConfirmationResponse> {
  try {
    await Auth.resendSignUp(username);
    return { status: "SUCCESS" };
  } catch (e) {
    reportError("Error re-sending email confirmation", e);
    return { status: "ERROR" };
  }
}
