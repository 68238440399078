import { connect } from "../../store/connect";
import { LogoutButton } from "./logout-button";
import {} from "../../store/lenses";

export const LogoutButtonContainer = connect(
  (state) => ({}),
  (dispatch) => ({
    logout: () => {
      dispatch({ type: "REQUEST_LOGOUT" });
    },
  })
)(LogoutButton);
