import React from "react";
import { ResetPasswordErrors } from "../../store/state";
import { WithSession, hasActiveSession, contentOrLoading } from "../../util";
import { TextInput, TextInputProps } from "../../components/text-input";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { Button } from "../../components/button";
import { APP_ROUTES } from "../../router";
import "./reset-password-screen.css";

export type ResetPasswordScreenProps = {
  loading: boolean;
  email?: string;
  errors: ResetPasswordErrors;
  submitCodeAndPassword: (
    email: string,
    code: string,
    password: string
  ) => void;
} & WithSession;

const emailDisabled = (email: string) => {
  return email === "" || isNil(email);
};

const codeAndPasswordDisabled = (code: string, password: string) => {
  return code === "" || isNil(code) || password === "" || isNil(password);
};

export function ResetPasswordScreen(props: ResetPasswordScreenProps) {
  const { email, errors, submitCodeAndPassword, loading } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const [resetEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  let inputProps: TextInputProps = {
    buttonStyle: "BASE",
    label: t("verifyEmail.confirmationCode"),
    value: code,
    onChange: setCode,
    helperText: errors.limitExceeded
      ? t("errors.slowDown")
      : errors.invalidCode
      ? t("errors.invalidConfirmationCode")
      : errors.unknownError
      ? t("errors.genericError")
      : undefined,
    error:
      errors.invalidCode ||
      errors.invalidEmail ||
      errors.limitExceeded ||
      errors.unknownError,
  };

  const passwordComponent = isNil(email) ? (
    <></>
  ) : (
    <TextInput
      buttonStyle="PASSWORD"
      label={t("inputs.passwordNew")}
      value={newPassword}
      onChange={setNewPassword}
      helperText={t("inputs.passwordHelp")}
      error={errors.invalidPassword}
    />
  );

  const disabled = isNil(email)
    ? emailDisabled(resetEmail)
    : codeAndPasswordDisabled(code, newPassword);
  const maybeSubmit = disabled
    ? () => {}
    : () => !isNil(email) && submitCodeAndPassword(email, code, newPassword);

  const resetForm = (
    <form className="reset-password">
      {/* <IconEmail /> */}
      {props.email && (
        <div className="reset-password-code-sent">
          <span className="reset-password-code-sent-success">{`${t(
            "resetPassword.success"
          )}`}</span>
          <span>{`${t("resetPassword.codeSent.part1")} ${props.email}`}</span>
        </div>
      )}
      <div className="text-inputs-wrap">
        <TextInput {...inputProps} />
        <span className="reset-password-create-password">{`${t(
          "resetPassword.codeSent.part2"
        )}`}</span>
        {passwordComponent}
      </div>
      <div className="reset-password-submit-btn-wrap">
        <Button
          buttonStyle="OPAQUE"
          disabled={disabled}
          text={t("verifyEmail.submit")}
          onPress={maybeSubmit}
        />
      </div>
    </form>
  );
  return contentOrLoading(resetForm, "reset-password", loading);
}
