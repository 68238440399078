import React from "react";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

export type CheckBoxProps = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export function CheckBox(props: CheckBoxProps) {
  const { checked, onChange } = props;

  return (
    <Checkbox
      checked={checked}
      onChange={(_, checked) => onChange(checked)}
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
}
