import { AuthOps } from "..";
import { CallReturnType, putAction } from "../../../util";
import { call } from "redux-saga/effects";
import { updatePlatforms } from "../../../auth/session";
import { setBugsnagUser } from "../../../api/bugsnag";

export function* loadExistingSession(ops: AuthOps["session"]) {
  const response: CallReturnType<typeof ops.getSession> = yield call(
    ops.getSession
  );

  if (response.status === "SUCCESS") {
    yield call(updatePlatforms);
    yield call(setBugsnagUser);
  }

  yield putAction({ type: "UPDATE_SESSION", response });
}
