import { Action } from "../actions";
import { AppState, initialAppState } from "../state";
import produce from "immer";
import { signupReducer } from "./signup";
import { loginReducer } from "./login";
import { invitationCodeReducer } from "./invitation-code";
import { confirmationReducer } from "./confirmation";
import {
  session,
  confirmationUnconfirmed,
  loading,
  externalSurveySessions,
} from "../lenses";
import { flow } from "lodash";
import { resetReducer } from "./reset-password";
import { handleEmailChannel } from "../../api/onesignal";

export type AppReducer = (state: AppState, action: Action) => AppState;

export const appReducer = (state = initialAppState(), action: Action) => {
  switch (action.type) {
    case "REQUEST_SIGNUP":
    case "SIGNUP_RESPONSE":
    case "CLEAR_SIGNUP_CREDS":
      return signupReducer(state, action);
    case "REQUEST_LOGIN":
    case "REQUEST_LOGOUT":
    case "LOGIN_RESPONSE":
    case "LOGOUT_RESPONSE":
      return loginReducer(state, action);
    case "REQUEST_CHECK_INVITATION":
    case "REQUEST_CONSUME_INVITATION":
    case "CHECK_INVITATION_RESPONSE":
    case "CONSUME_INVITATION_RESPONSE":
    case "CLEAR_INVITATION_ERRORS":
      return invitationCodeReducer(state, action);
    case "RECEIVE_URL_PARAMS":
      return produce(state, (newState) => {
        newState.urlParams = action.params;
      });
    case "REQUEST_CONFIRM_EMAIL":
    case "CONFIRM_EMAIL_RESPONSE":
      return confirmationReducer(state, action);
    case "REQUEST_RESET_PASSWORD":
    case "REQUEST_RESET_PASSWORD_SUBMIT":
    case "RESET_PASSWORD_SUBMIT_RESPONSE":
    case "RESET_PASSWORD_RESPONSE":
      return resetReducer(state, action);
    case "UPDATE_SESSION":
      if (action.response.status === "SUCCESS") {
        handleEmailChannel(action.response.session);
        return flow(
          loading.set(false),
          session.set(action.response.session),
          confirmationUnconfirmed.set(false)
        )(state);
      }
      return loading.set(false)(state);
    case "GET_EXTERNAL_SURVEY_SESSIONS":
      return flow(loading.set(true))(state);
    case "GOT_EXTERNAL_SURVEY_SESSIONS":
      return flow(
        externalSurveySessions.set(action.externalSurveySessions),
        loading.set(false)
      )(state);
    case "CLEAR_RESET_EMAIL":
      return resetReducer(state, action);
    default:
      return state;
  }
};
