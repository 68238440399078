import React, { Fragment } from "react";
import Bugsnag from "@bugsnag/js";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./base-app.css";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./theme";
import { APP_ROUTES } from "./router";
import { useTranslation } from "react-i18next";
import { Button } from "./components/button";
import { RUNNING_IN_DEV } from "./environment";
import { configureStore } from "./store/create-store";
import { initOneSignal } from "./api/onesignal";
import { initBugsnag } from "./api/bugsnag";

initOneSignal();
initBugsnag();

export function BaseApp() {
  const { i18n } = useTranslation();

  const {
    code,
    signup,
    login,
    home,
    landing,
    confirm,
    frontDoor,
    resetEmail,
    resetPassword,
    deepLink,
    errorScreen,
  } = APP_ROUTES;

  const debugSwapLanguage = () => {
    const next = i18n.language === "es" ? "en" : "es";
    i18n.changeLanguage(next);
  };

  const langSwitcher = RUNNING_IN_DEV ? (
    <Button
      text="DEBUG: Switch lang"
      onPress={debugSwapLanguage}
      buttonStyle="OUTLINE"
    />
  ) : (
    <></>
  );

  const { store, persistor } = configureStore();
  const ErrorBoundary =
    Bugsnag.getPlugin("react")?.createErrorBoundary(React) || Fragment;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <Router>
            <ErrorBoundary>
              <div className="app">
                <div className="stars" />
                <Switch>
                  <Route path={code.pathId}>{code.component}</Route>
                  <Route path={signup.pathId}>{signup.component}</Route>
                  <Route path={login.pathId}>{login.component}</Route>{" "}
                  <Route path={resetPassword.pathId}>
                    {resetPassword.component}
                  </Route>
                  <Route path={resetEmail.pathId}>{resetEmail.component}</Route>
                  <Route path={home.pathId}>{home.component}</Route>
                  <Route path={landing.pathId}>{landing.component}</Route>
                  <Route path={confirm.pathId}>{confirm.component}</Route>
                  <Route path={deepLink.pathId}>{deepLink.component}</Route>
                  <Route path={errorScreen.pathId}>
                    {errorScreen.component}
                  </Route>
                  <Route path={frontDoor.pathId}>{frontDoor.component}</Route>
                </Switch>
                {langSwitcher}
              </div>
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}
