import { connect } from "../../store/connect";
import { ConfirmEmailScreen } from "./confirm-email-screen";
import {
  confirmationLoading,
  session as sessionLens,
  confirmationErrors,
  signup,
} from "../../store/lenses";

export const ConfirmEmailScreenContainer = connect(
  (state) => ({
    session: sessionLens(state),
    loading: confirmationLoading(state),
    errors: confirmationErrors(state),
    state: signup(state),
  }),
  (dispatch) => ({
    submit: (code: string, email: string, password: string) => {
      dispatch({ type: "REQUEST_CONFIRM_EMAIL", code, email, password });
    },
    resend: (email: string) => {
      dispatch({ type: "REQUEST_RESEND_CONFIRMATION", email });
    },
    clearCreds: () => {
      dispatch({ type: "CLEAR_SIGNUP_CREDS" });
    },
  })
)(ConfirmEmailScreen);
