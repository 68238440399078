import React from "react";
import { useTranslation } from "react-i18next";
import { IconCheck } from "../../components/icons";

import "../widgets.css";
import "./email-confirmed.css";

export function EmailConfirmedWidget(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="email-confirmed-widget widget-card">
      <IconCheck on />
      <div className="email-confirmed-widget-info">
        <span className="email-confirmed-widget-title widget-title">
          {t("widgets.emailConfirmedTitle")}
        </span>
        <span className="widget-description">
          {t("widgets.emailConfirmedDescription")}
        </span>
      </div>
    </div>
  );
}
