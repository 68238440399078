import React from "react";
import { isNil } from "lodash";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Effect, SimpleEffect, take, put } from "redux-saga/effects";
import { Action } from "./store/actions";
import { AppSession } from "./store/state";
import { LoadingSpinner } from "./components/loading-spinner";
import { getCredentials } from "./api/user";
import { AWS_ENV } from "./environment";

type PromiseContents<T extends Promise<any>> = T extends Promise<infer R>
  ? R
  : never;

export type AsyncReturnType<T extends (...args: any[]) => Promise<any>> =
  PromiseContents<ReturnType<T>>;

type StripEffects<T> = T extends IterableIterator<infer E>
  ? E extends Effect | SimpleEffect<any, any>
    ? never
    : E
  : never;

type DecideReturn<T> = T extends Promise<infer R>
  ? R
  : T extends IterableIterator<any>
  ? StripEffects<T>
  : T;

export type CallReturnType<T extends (...args: any[]) => any> = DecideReturn<
  ReturnType<T>
>;

export type WithSession = {
  session?: AppSession;
};

export const hasActiveSession = (props: WithSession) => {
  return !isNil(props.session);
};

export const putAction = (a: Action) => put(a);

export const takeAction = (a: Action["type"] | Action["type"][]) => take(a);

export function processKeyWithDefault(key: string, defaultVal: string): string {
  const maybe = process.env[key];
  if (!isNil(maybe)) {
    return maybe!;
  }
  return defaultVal;
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function emailIsValid(email: string): boolean {
  return !!email.match(/^\S+@\S+\.\S+$/);
}

export function nameIsValid(name: string): boolean {
  // TODO: Double check unicode behavior
  return !!name.match(/\S+/); // at least one non-space character
}

export function passwordIsValid(password: string): boolean {
  const longEnough = password.length >= 8;
  const hasNumber = !!password.match(/(?=.*[0-9])/);
  const hasLowerCase = !!password.match(/(?=.*[a-z])/);
  const hasNoSpaces = password.indexOf(" ") === -1;
  return longEnough && hasNumber && hasLowerCase && hasNoSpaces;
}

export async function buildGqlClient(): Promise<AWSAppSyncClient<any>> {
  const credentials = await getCredentials();
  if (!isNil(credentials)) {
    return new AWSAppSyncClient({
      url: AWS_ENV.gqlApi,
      region: AWS_ENV.region,
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials,
      },
      disableOffline: true,
    }).hydrated();
  }

  throw new Error(`No credentials in config ${credentials}`);
}

export function contentOrLoading(
  component: JSX.Element,
  wrapperClass: string,
  loading: boolean
) {
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner wrapperClass={wrapperClass} />
      </div>
    );
  } else {
    return component;
  }
}

export function openInNewTab(link: string) {
  window.open(link, "_blank");
}

export function openInCurrentTab(link: string) {
  window.open(link, "_self");
}

export function normalizeEmail(email: string): string {
  return email.toLocaleLowerCase().trim();
}

type ParsedParams = {
  inviteCode: string;
  participantId: string;
  subCohort: string;
};

const PARTICIPANT_ID_KEY = "participantId";
const SUB_COHORT_KEY = "group";

/**
 * @desc Extracts participant id and/or subCohort from url starting with '?'
 */
export const parseUrlParams = (
  invitationCode: string,
  urlParam: string
): ParsedParams => {
  const params = new URLSearchParams(urlParam);
  const participantId = params.get(PARTICIPANT_ID_KEY);
  const subCohort = params.get(SUB_COHORT_KEY);
  return {
    inviteCode: invitationCode,
    participantId: participantId ?? "",
    subCohort: subCohort ?? "",
  };
};

/**
 * @desc Extracts participant id and/or subCohort from a legacy format URL parameter starting with '&'.
 * Note: This function is provided to support older implementations. Preferred way of parsing
 *   url parameters is to use parseUrlParams() function.
 */
export const parseLegacyUrlParams = (urlParam: string): ParsedParams => {
  return {
    inviteCode: urlParam.split("&")[0],
    participantId: getParameterByName(urlParam, PARTICIPANT_ID_KEY) ?? "",
    subCohort: getParameterByName(urlParam, SUB_COHORT_KEY) ?? "",
  };
};

/**
 * @desc Retrieve query string with name from url parameter
 */
const getParameterByName = (urlParam: string, name: string) => {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(urlParam);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
