import React from "react";
import { IconCheck } from "../../../components/icons";
import "./styles.css";

type Props = {
  t1Completed: boolean;
  t2Completed: boolean;
  dashLineHeight: number;
};

export const CheckIcons = ({
  t1Completed,
  t2Completed,
  dashLineHeight,
}: Props) => {
  return (
    <div className="check-icons-wrap">
      <IconCheck on={t1Completed} />
      <div
        className={`line-dashed`}
        style={{
          height: `${dashLineHeight}px`,
        }}
      />
      <IconCheck on={t2Completed} disabled={!t1Completed} />
    </div>
  );
};
