import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../button";

import "./logout-button.css";

type Props = {
  logout: () => void;
};

export const LogoutButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      id="btn-logout"
      text={t("logout.title").toUpperCase()}
      onPress={() => props.logout()}
      buttonStyle="TEXT"
    />
  );
};
