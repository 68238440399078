import {
  PathProgressInput,
  GeneratedLearnStatsInput,
  GeneratedPracticeStatsInput,
  GeneratedAppStatsInput,
} from "@hminnovations/graphql-types";

export function initialPathProgressInput(): PathProgressInput {
  return {
    currentActivityId: undefined,
    cohortName: "",
    mostRecentlyCompletedActivityId: undefined,
  };
}

export function initialGeneratedLearnStatsInput(): GeneratedLearnStatsInput {
  return {
    totalTimeSpentListeningToLearnAudio: 0,
    timeOfFirstLearnAction: null,
  };
}

export function initialGeneratedPracticeStatsInput(): GeneratedPracticeStatsInput {
  return {
    totalTimeSpentPracticing: 0,
    timeOfFirstPracticeAction: null,
    timeSpentByDuration: [],
    timeSpentByPosture: [],
    timeSpentBySpeaker: [],
  };
}

export function initialGeneratedAppStatsInput(): GeneratedAppStatsInput {
  return {
    appOpeningStreak: [],
    appUsageTime: 0,
  };
}
