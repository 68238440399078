import React from "react";
import checkIcon from "../../../assets/icon-check.svg";

import "./styles.css";

type Props = {
  on: boolean;
  disabled?: boolean;
};

export const IconCheck = (props: Props) => {
  const renderCheckIcon = () => {
    // Render check icon image
    if (props.on) {
      return (
        <img
          className="icon-check-on icon-check"
          src={checkIcon}
          alt="check-icon"
        />
      );
    }

    // Render grey empty circle
    if (props.disabled)
      return <div className="icon-check-disabled icon-check" />;

    // Render white empty circle
    return <div className="icon-check-off icon-check" />;
  };
  return renderCheckIcon();
};
