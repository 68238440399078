import { isNil } from "lodash";
import OneSignal from "react-onesignal";
import { RUNNING_IN_DEV } from "../environment";
import { AppSession } from "../store/state";
import { reportError } from "./bugsnag-logger";

const ONE_SIGNAL_APP_ID = RUNNING_IN_DEV
  ? "328e98cb-6bba-487d-ad5a-4e039ed11ca6"
  : "577c4f91-8adf-43c2-9700-528f7d3e23e4";

export type HashEmailPayload = {
  name: "HASH_EMAIL";
  email: string;
};

export const initOneSignal = () => {
  if (RUNNING_IN_DEV) {
    console.log("Initializing one signal");
  }

  OneSignal.init({
    appId: ONE_SIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: RUNNING_IN_DEV ? true : false,
  });
};

export const handleEmailChannel = async (session: AppSession) => {
  const userInfo = session.user;
  const cohort = session.cohort;
  const email = userInfo.email?.toLowerCase();
  const NOT_SET = "NOT_SET";
  OneSignal.setEmail(email);
  // Append portal to platforms tags in case current session
  //  doesn't have the most up-to-date value
  const platforms =
    userInfo.platforms === "app" ? "app portal" : userInfo.platforms;
  const tags = {
    email: email || NOT_SET,
    name: userInfo.name || NOT_SET,
    cohortName: cohort.name || NOT_SET,
    cohort: cohort.path || NOT_SET,
    invitationCode: "",
    participantId: userInfo.participantId || NOT_SET,
    platforms: platforms || NOT_SET,
    subCohort: userInfo.subCohort || NOT_SET,
    microsupportEnabled: isNil(cohort.microsupportEnabled)
      ? NOT_SET
      : !!cohort.microsupportEnabled
      ? "true"
      : "false",
  };
  OneSignal.setExternalUserId(email);
  OneSignal.sendTags(tags);
};

export const handleLogoutOneSignal = async (session: AppSession) => {
  let db: IDBDatabase;
  if (indexedDB) {
    const DB_NAME = "ONE_SIGNAL_SDK_DB";
    const OBJ_STORE_NAME = "Ids";
    const OBJ_NAME_TO_BE_REMOVED = "emailProfile";
    // Load IDB
    const req: IDBOpenDBRequest = indexedDB.open(DB_NAME);
    req.onerror = (e: any) => {
      reportError("Error loading database: ", e.target.error);
    };
    req.onsuccess = (e) => {
      db = (e.target as IDBOpenDBRequest).result;
      try {
        // Delete transaction removing emailProfile object
        const deleteReq = db
          .transaction([OBJ_STORE_NAME], "readwrite")
          .objectStore(OBJ_STORE_NAME)
          .delete(OBJ_NAME_TO_BE_REMOVED);

        deleteReq.onerror = (e: any) => {
          reportError(
            `Error deleting ${OBJ_NAME_TO_BE_REMOVED} object in ${DB_NAME} IDB: `,
            e.target.error
          );
        };
        deleteReq.onsuccess = (e: any) => {
          console.log(`Successfully removed ${OBJ_NAME_TO_BE_REMOVED} object`);
        };
      } catch (err) {
        reportError(
          `Error deleting ${OBJ_NAME_TO_BE_REMOVED} key in ${DB_NAME} IDB:`,
          err
        );
      }
    };
  } else {
    reportError("Could not access indexedDB object");
  }
};
