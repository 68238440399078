import Bugsnag from "@bugsnag/js";

const disableLogging = false;
const dontTellBugsnag = false;

const convertToBugsnagString = (o: any): string => {
  switch (typeof o) {
    case "object":
      const stringified = JSON.stringify(o);
      return stringified === "{}" ? o : stringified;
    case "function":
      return o.toString();
    default:
      return `${o}`;
  }
};

export function reportWarning(message: string, o?: any) {
  if (disableLogging) return;
  console.warn(message, o);
  if (Bugsnag && !dontTellBugsnag) {
    const warn = convertToBugsnagString(o);
    Bugsnag.notify(new Error(`${message}. ${warn}`), (report) => {
      report.severity = "warning";
    });
  }
}

export function reportLog(message: string, o?: any) {
  if (disableLogging) return;
  console.log(message, o);
  if (Bugsnag && !dontTellBugsnag) {
    const log = convertToBugsnagString(o);
    Bugsnag.notify(new Error(`${message}. ${log}`), (report) => {
      report.severity = "info";
    });
  }
}

export function reportError(message: string, o?: any) {
  if (disableLogging) return;
  console.error(message, o);
  if (Bugsnag && !dontTellBugsnag) {
    const err = convertToBugsnagString(o);
    Bugsnag.notify(new Error(`${message}. ${err}`), (report) => {
      report.severity = "error";
    });
  }
}
