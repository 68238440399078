import React from "react";
import qrCodeIconDev from "../../../assets/icon-qrcode-dev.png";
import qrCodeIconProd from "../../../assets/icon-qrcode-prod.png";
import { RUNNING_IN_DEV } from "../../../environment";

import "./styles.css";

export const IconQRCode = () => {
  return (
    <img
      className="icon-qrcode"
      src={RUNNING_IN_DEV ? qrCodeIconDev : qrCodeIconProd}
      alt="qrcode-icon"
    />
  );
};
