import React from "react";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/text-input";
import { Button } from "../../components/button";
import {
  WithSession,
  hasActiveSession,
  contentOrLoading,
  openInNewTab,
} from "../../util";
import { APP_ROUTES } from "../../router";
import { useHistory } from "react-router-dom";
import { ConfirmationErrors, SignupState } from "../../store/state";
import { CohortLogoContainer } from "../../components/cohort-logo";

import "./confirm-email-screen.css";

const HELP_LINK =
  "https://hminnovations.zendesk.com/hc/en-us/articles/360034820831-I-created-an-account-but-I-did-not-receive-a-6-digit-email-confirmation-code-Now-what-";

export type ConfirmEmailProps = {
  loading: boolean;
  state: SignupState;
  errors: ConfirmationErrors;
  submit: (code: string, email: string, password: string) => void;
  resend: (email: string) => void;
  clearCreds: () => void;
} & WithSession;

export function ConfirmEmailScreen(props: ConfirmEmailProps) {
  const { submit, resend, loading, errors, state, clearCreds } = props;
  const { credentials: creds, cohort } = state;
  const { t } = useTranslation();
  const history = useHistory();

  const [code, setCode] = React.useState("");
  const [resent, setResent] = React.useState(false);

  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  if (isNil(creds)) {
    if (errors.invalidCredentials) {
      const loginPath = APP_ROUTES.login.toPath();
      history.push(loginPath);
    } else if (isNil(cohort)) {
      const frontDoor = APP_ROUTES.frontDoor.toPath();
      history.push(frontDoor);
    } else {
      const signup = APP_ROUTES.signup.toPath();
      history.push(signup);
    }
  }

  const newCodeSent = (
    <p className={`confirm-text resent${resent ? " fade-in" : ""}`}>
      {resent ? t("verifyEmail.newCodeSent") : ""}
    </p>
  );
  const disabled = isNil(creds) || code === "";
  const maybeSubmit = disabled
    ? () => {}
    : () => submit(code, creds!.email, creds!.password);
  const maybeResend = isNil(creds)
    ? () => {}
    : () => {
        setResent(true);
        resend(creds.email);
      };

  const confirmForm = (
    <div className="confirm-form-wrap">
      <form
        className="confirm-form"
        onSubmit={(e) => {
          e.preventDefault();
          maybeSubmit();
        }}
      >
        <CohortLogoContainer />
        <p className="confirm-header">{t("verifyEmail.almostThere")}</p>
        <p className="confirm-text">
          {t("verifyEmail.sentTo")}{" "}
          <span className="confirm-text-email">{creds?.email}</span>
        </p>
        {newCodeSent}
        <TextInput
          label={t("verifyEmail.confirmationCode")}
          value={code}
          onChange={setCode}
          buttonStyle="WHITE"
          error={errors.invalidCode ? true : undefined}
          helperText={
            errors.invalidCode ? t("verifyEmail.invalidCode") : undefined
          }
        />
        <Button
          buttonStyle="TEXT"
          text={t("verifyEmail.resendCode")}
          onPress={maybeResend}
        />
        <Button
          buttonStyle="OPAQUE"
          text={t("verifyEmail.submit")}
          onPress={maybeSubmit}
        />
        <ul className="confirm-list">
          <li>
            <p>
              {t("verifyEmail.newAccount")}
              <span onClick={clearCreds}>
                {t("verifyEmail.newAccountLink")}
              </span>
            </p>
          </li>
          <li>
            <p>
              {t("verifyEmail.spamWarning")}
              <strong>{t("verifyEmail.spamWarningEmail")}</strong>
            </p>
          </li>
        </ul>
        <Button
          buttonStyle="TEXT"
          text={t("verifyEmail.needHelp")}
          onPress={() => openInNewTab(HELP_LINK)}
        />
      </form>
    </div>
  );

  return contentOrLoading(confirmForm, "confirm-form", loading);
}
