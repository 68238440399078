import { connect } from "../../store/connect";
import { ResetPasswordEmailScreen } from "./reset-password-email-screen";
import {
  loading as loadingLens,
  session as sessionLens,
  resetErrors,
  email,
} from "../../store/lenses";

export const ResetPasswordEmailScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
    session: sessionLens(state),
    errors: resetErrors(state),
    email: email(state),
  }),
  (dispatch) => ({
    submitEmail: (email: string) => {
      dispatch({ type: "REQUEST_RESET_PASSWORD", email });
    },
  })
)(ResetPasswordEmailScreen);
