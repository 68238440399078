import * as GQLTypes from "@hminnovations/graphql-types";
import gql from "graphql-tag";
import { buildGqlClient } from "../util";
import { CohortInfo } from "../store/state";
import { query } from "./gql";
import { isNil } from "lodash";

interface GetCohortResult {
  getCohort: GQLTypes.Cohort | null;
}

const GetCohortQuery = gql`
  query GetCohort($creds: CohortCreds!) {
    getCohort(creds: $creds) {
      id
      name
      pathUri
      logoUri
      t1SurveyURL
      t2SurveyURL
      challengeEnabled
      microsupportEnabled
    }
  }
`;

export const getCohortInfo = async (
  creds: GQLTypes.CohortCreds
): Promise<CohortInfo | undefined> => {
  const client = await buildGqlClient();
  const res = await query<GetCohortResult, GQLTypes.GetCohortQueryArgs>(
    GetCohortQuery,
    { creds },
    client
  );
  const cohort = res && res.getCohort ? res.getCohort : undefined;

  if (!isNil(cohort)) {
    return {
      id: cohort.id,
      name: cohort.name,
      uri: cohort.logoUri,
      path: cohort.pathUri,
      t1SurveyURL: cohort.t1SurveyURL ?? undefined,
      t2SurveyURL: cohort.t2SurveyURL ?? undefined,
      challengeEnabled: cohort.challengeEnabled ?? undefined,
      microsupportEnabled: cohort.microsupportEnabled ?? undefined,
    };
  }
};
