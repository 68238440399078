import { connect } from "../../store/connect";
import { SignupScreen } from "./signup-screen";
import {
  userInfo,
  invitationCode,
  loading as loadingLens,
  confirmationUnconfirmed,
  signupCohort,
  signupErrors,
  urlParticipantId,
  urlSubCohort,
} from "../../store/lenses";
import { SignupCredentials } from "../../auth/signup";

export const SignupScreenContainer = connect(
  (state) => ({
    unconfirmed: confirmationUnconfirmed(state),
    loading: loadingLens(state),
    invitation: invitationCode(state),
    cohort: signupCohort(state),
    user: userInfo(state),
    errors: signupErrors(state),
    urlParticipantId: urlParticipantId(state),
    urlSubCohort: urlSubCohort(state),
  }),
  (dispatch) => ({
    submit: (creds: SignupCredentials) => {
      dispatch({ type: "REQUEST_SIGNUP", creds });
    },
  })
)(SignupScreen);
