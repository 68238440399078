import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/text-input";
import { WithSession, contentOrLoading, hasActiveSession } from "../../util";
import { APP_ROUTES } from "../../router";
import { useHistory } from "react-router-dom";
import { InvitationErrors } from "../../store/state";
import { Button } from "../../components/button";
import "./invitation-code-screen.css";
import { HmiLogo } from "../../components/hmi-logo";

export type InvitationCodeScreenProps = {
  loading: boolean;
  codeAccepted: boolean;
  errors: InvitationErrors;
  submit: (code: string) => void;
} & WithSession;

export function InvitationCodeScreen(props: InvitationCodeScreenProps) {
  const { loading, codeAccepted, errors, submit } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [code, setCode] = React.useState("");

  /**
   * If we already have a session, redirect them to the home screen
   */
  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  /**
   * If we've already got an accepted code, redirect to the signup
   */
  if (codeAccepted) {
    const signupPath = APP_ROUTES.signup.toPath();
    history.push(signupPath);
  }

  const disabled = code === "";
  const maybeSubmit = disabled ? () => {} : () => submit(code);

  const codeScreen = (
    <form
      className="invitation-form"
      onSubmit={(e) => {
        e.preventDefault();
        maybeSubmit();
      }}
    >
      <HmiLogo />
      <div className="text-input-wrap">
        <TextInput
          buttonStyle="BASE"
          label={t("invitationCode.title")}
          value={code}
          onChange={setCode}
          error={errors.invalidCode || errors.alreadyClaimed ? true : undefined}
          helperText={
            errors.invalidCode || errors.alreadyClaimed
              ? t("verifyEmail.invalidCode")
              : undefined
          }
        />
      </div>
      <Button
        buttonStyle="OPAQUE"
        disabled={disabled}
        text={t("invitationCode.submit")}
        onPress={maybeSubmit}
      />
    </form>
  );

  return contentOrLoading(codeScreen, "invitation-form", loading);
}
