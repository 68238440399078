import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { es } from "./lang/es";
import { en } from "./lang/en";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = { es, en };

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: 'en',
  keySeparator: ".",
  interpolation: { escapeValue: false },
});

export default i18n;
