import { partial } from "lodash";

const win: (Window & typeof globalThis) | { [key: string]: any } =
  typeof window !== "undefined" ? window : {};

const localStorage = win.localStorage;
const LOCAL_STORAGE_KEY = "_l_key_";

const isEmpty = (storage: Storage | {}) => {
  return !storage || storage === {};
};

const getValueFrom = (storage: Storage, key: string) => {
  if (isEmpty(storage)) {
    return;
  }
  const rawData = storage.getItem(key);
  if (!rawData) {
    return;
  }
  return JSON.parse(rawData);
};

const setValueTo = (storage: Storage, key: string, data: any) => {
  if (isEmpty(storage)) {
    return;
  }
  return storage.setItem(key, JSON.stringify(data));
};

const removeValueFrom = (storage: Storage, key: string) => {
  if (isEmpty(storage)) {
    return;
  }
  return storage.removeItem(key);
};

const setValueToLocalStorage = partial(setValueTo, localStorage);
const getValueFromLocalStorage = partial(getValueFrom, localStorage);
const removeValueFromLocalStorage = partial(removeValueFrom, localStorage);

export const getDataFromLocalStorage = (name: string) => {
  return getValueFromLocalStorage(`${LOCAL_STORAGE_KEY}/${name}`);
};
export const setDataToLocalStorage = (name: string, val: any) => {
  return setValueToLocalStorage(`${LOCAL_STORAGE_KEY}/${name}`, val);
};
export const removeDataFromLocalStorage = (name: string) => {
  return removeValueFromLocalStorage(`${LOCAL_STORAGE_KEY}/${name}`);
};

// List of keys being used for storage
export const KEY_T1_ACCESSED = "t1Accessed";
