import * as GQLTypes from "@hminnovations/graphql-types";
import gql from "graphql-tag";
import { buildGqlClient } from "../util";
import { ExternalSurveySession } from "../store/state";
import { query } from "./gql";
import { reportError } from "./bugsnag-logger";

interface GetExternalSurveySessionResult {
  getExternalSurveySessions: GQLTypes.ExternalSurveySession[] | null;
}

const GetExternalSurveySessionQuery = gql`
  query GetExternalSurveySession($creds: UserItemCreds!) {
    getExternalSurveySessions(creds: $creds) {
      id
      score {
        rawScore
        percentile
        category
      }
      startedAt
      surveyId
    }
  }
`;

export const getExternalSurveySession = async (
  creds: GQLTypes.UserItemCreds
): Promise<ExternalSurveySession[]> => {
  const client = await buildGqlClient();
  var res;
  try {
    res = await query<
      GetExternalSurveySessionResult,
      GQLTypes.GetExternalSurveySessionsQueryArgs
    >(GetExternalSurveySessionQuery, { creds }, client);
  } catch (e) {
    reportError("Error while getting external survey session", e);
  }

  const externalSurveySessions =
    res && res.getExternalSurveySessions
      ? res.getExternalSurveySessions
      : undefined;

  return (externalSurveySessions ?? []).map((survey) => {
    return {
      id: survey.id,
      score: survey.score ?? undefined,
      startedAt: survey.startedAt,
      surveyId: survey.surveyId,
    };
  });
};
