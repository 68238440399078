import { connect } from "../../store/connect";
import { LandingScreen } from "./landing-screen";
import {
  session as sessionLens,
  inviteAccepted,
  loading as loadingLens,
  invitationErrors,
} from "../../store/lenses";
import { UrlParams } from "../../store/state";

export const LandingScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
    invitationAccepted: inviteAccepted(state),
    invitationErrors: invitationErrors(state),
    session: sessionLens(state),
  }),
  (dispatch) => ({
    submitCode: (code: string) => {
      dispatch({ type: "REQUEST_CHECK_INVITATION", code });
    },
    setParams: (params: UrlParams) => {
      dispatch({ type: "RECEIVE_URL_PARAMS", params });
    },
  })
)(LandingScreen);
