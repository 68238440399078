import { connect } from "../../store/connect";
import { loading as loadingLens } from "../../store/lenses";
import { ErrorScreen } from "./error-screen";

export const ErrorScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
  }),
  (dispatch) => ({
    clearInvitationErrors: () => {
      dispatch({ type: "CLEAR_INVITATION_ERRORS" });
    },
  })
)(ErrorScreen);
