import React from "react";
import {
  CohortInfo,
  InvitationStatus,
  SignupErrors,
  UrlParams,
} from "../../store/state";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/text-input";
import { SignupCredentials } from "../../auth/signup";
import { isNil } from "lodash";
import "./signup-screen.css";
import { WithSession, contentOrLoading, hasActiveSession } from "../../util";
import { APP_ROUTES } from "../../router";
import { useHistory } from "react-router-dom";
import { Button, ButtonProps } from "../../components/button";
import { CheckBox } from "../../components/check-box";
import { CohortLogoContainer } from "../../components/cohort-logo";

export type SignupScreenProps = {
  unconfirmed: boolean;
  loading: boolean;
  cohort?: CohortInfo;
  invitation: InvitationStatus;
  errors: SignupErrors;
  submit: (creds: SignupCredentials) => void;
  urlParticipantId: UrlParams["participantId"];
  urlSubCohort: UrlParams["subCohort"];
} & WithSession;

const TOS =
  "https://hminnovations.zendesk.com/hc/en-us/articles/115003171092-End-User-License-Agreement-EULA-Terms-of-Use";
const PRIVACY =
  "https://hminnovations.zendesk.com/hc/en-us/articles/115003171112-Privacy-Policy";

export function SignupScreen(props: SignupScreenProps) {
  const { invitation, cohort, errors, urlParticipantId, urlSubCohort } = props;
  const { t } = useTranslation();
  const history = useHistory();

  // Local state hooks
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [tosChecked, setTosChecked] = React.useState(false);
  const [privacyChecked, setPrivacyChecked] = React.useState(false);

  /**
   * If we already have a session, redirect them to the home screen
   */
  if (hasActiveSession(props) && !props.unconfirmed) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  /**
   * if we're in an 'email unconfirmed' state, route them to the email
   * confirmation screen
   */
  if (props.unconfirmed) {
    const confirmPath = APP_ROUTES.confirm.toPath();
    history.push(confirmPath);
  }

  /**
   * If we don't have an accepted invite code yet, redirect to the error screen
   */
  if (!invitation.accepted || isNil(invitation.code)) {
    const errorScreenPath = APP_ROUTES.errorScreen.toPath();
    history.push(errorScreenPath);
  }

  // Build signup submission handler
  let submitHandler: ButtonProps["onPress"] = () => {};
  if (!isNil(cohort)) {
    const fullName = `${firstName.trim()} ${lastName.trim()}`;

    const creds: SignupCredentials = {
      assignParticipant: true,
      signup: {
        invitationCode: invitation.code!,
        name: fullName,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      },
      cohort,
      login: {
        type: "EMAIL",
        email,
        password,
      },
      urlParticipantId,
      urlSubCohort,
    };

    submitHandler = () => props.submit(creds);
  }

  const disabled =
    firstName.trim() === "" ||
    lastName.trim() === "" ||
    email.trim() === "" ||
    !tosChecked ||
    !privacyChecked;
  const maybeSubmit = disabled ? () => {} : submitHandler;
  const emailHelperText = errors.invalidEmail
    ? t("errors.invalidEmailOrPassword")
    : errors.alreadyExists
    ? t("errors.extantEmail")
    : undefined;
  const emailError =
    errors.invalidEmail || errors.alreadyExists ? true : undefined;

  const signupComponent = (
    <div className="signup-form-wrap">
      <form
        className="signup-form"
        onSubmit={(e) => {
          e.preventDefault();
          maybeSubmit();
        }}
      >
        <CohortLogoContainer />
        <p className="header-text">{t("createAccount.headerText")}</p>
        <div className="text-inputs-wrap">
          <div className="signup-input">
            <TextInput
              buttonStyle={"BASE"}
              label={t("inputs.firstName")}
              value={firstName}
              onChange={setFirstName}
            />
          </div>
          <div className="signup-input">
            <TextInput
              buttonStyle={"BASE"}
              label={t("inputs.lastName")}
              value={lastName}
              onChange={setLastName}
            />
          </div>
          <div className="signup-input">
            <TextInput
              buttonStyle={"BASE"}
              label={t("inputs.email")}
              value={email}
              onChange={setEmail}
              error={emailError}
              helperText={emailHelperText}
            />
          </div>
          <div className="signup-input">
            <TextInput
              buttonStyle={"PASSWORD"}
              label={t("inputs.password")}
              value={password}
              onChange={setPassword}
              error={errors.invalidPassword ? true : undefined}
              helperText={t("inputs.passwordHelp")}
            />
          </div>
        </div>
        <div className="signup-checks-wrap">
          <div className="signup-check">
            <CheckBox checked={tosChecked} onChange={setTosChecked} />
            <p>
              {t("terms.tos")}
              <a href={TOS} target="_blank" rel="noopener noreferrer">
                {t("terms.tosLink")}
              </a>
            </p>
          </div>
          <div className="signup-check">
            <CheckBox checked={privacyChecked} onChange={setPrivacyChecked} />
            <p>
              {t("terms.privacy")}
              <a href={PRIVACY} target="_blank" rel="noopener noreferrer">
                {t("terms.privacyLink")}
              </a>
            </p>
          </div>
        </div>
        <Button
          disabled={disabled}
          text={t("createAccount.submit")}
          onPress={maybeSubmit}
          buttonStyle="OPAQUE"
        />
        <div className="already-signed-in">
          <span>{t("login.already")} </span>
          <span className="login-link" onClick={gotoLogin}>
            {t("login.title")}
          </span>
        </div>
        <p className="signup-health">{t("createAccount.healthWarning")}</p>
      </form>
    </div>
  );

  function gotoLogin() {
    history.push("/login");
  }

  return contentOrLoading(signupComponent, "signup-form", props.loading);
}
