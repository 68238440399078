import { take, call, select, takeEvery } from "redux-saga/effects";
import { AuthOps } from "..";
import { RequestSignup, SignupResponse } from "../../actions";
import { CallReturnType, putAction } from "../../../util";
import { inviteText } from "../../lenses";

function* handleSignup(ops: AuthOps["signup"], action: RequestSignup) {
  const response: CallReturnType<typeof ops["doSignup"]> = yield call(
    ops.doSignup,
    action.creds
  );
  yield putAction({ type: "SIGNUP_RESPONSE", response });

  if (response.status === "SUCCESS") {
    const code: ReturnType<typeof inviteText> = yield select(inviteText);

    if (code !== undefined) {
      yield putAction({ type: "REQUEST_CONSUME_INVITATION", code });
    }
  }
}

function* consumeCodeOnSignup(action: SignupResponse) {
  if (action.response.status === "SUCCESS") {
    const code: ReturnType<typeof inviteText> = yield select(inviteText);
    if (code !== undefined) {
      yield putAction({ type: "REQUEST_CONSUME_INVITATION", code });
    } else {
      throw new Error("Unable to get invitation code to consume");
    }
  }
}

export function* signupWatcher(ops: AuthOps["signup"]) {
  yield takeEvery("SIGNUP_RESPONSE", function* (action) {
    yield call(consumeCodeOnSignup, action as SignupResponse);
  });

  while (true) {
    const requestSignup = yield take("REQUEST_SIGNUP");
    yield call(handleSignup, ops, requestSignup);
  }
}
