import React from "react";
import { CircularProgress } from "@material-ui/core";
import "./loading-spinner.css";

export type LoadingSpinnerProps = {
  wrapperClass: string;
};

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <div className={props.wrapperClass}>
      <CircularProgress className="loading-spinner" />
    </div>
  );
}
