import createSagaMiddleware from "redux-saga";
import { compose, applyMiddleware, createStore, Store } from "redux";
import { appReducer } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { rootSaga } from "./sagas";
import { RUNNING_IN_DEV } from "../environment";
import { PersistPartial } from "redux-persist/es/persistReducer";
import { AppState } from "./state";
import { Action } from "./actions";

export type StoreType = Store<AppState & PersistPartial, Action>;

export let store: undefined | StoreType = undefined;

export function configureStore(initialState = {}) {
  // Init store with redux dev tools
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

  store = createStore(
    persistReducer(
      {
        key: "root",
        storage,
      },
      appReducer
    ),
    enhancer
  );

  if (RUNNING_IN_DEV) {
    console.log("initialState", store.getState());
  }

  const persistor = persistStore(store, null, () => {
    if (RUNNING_IN_DEV) {
      // if you want to get restoredState
      console.log("restoredState", store!.getState());
    }
  });

  // Kick off root saga
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
