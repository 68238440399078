import { AppSession } from "../store/state";
import { getCredentials } from "../api/user";
import { isNil, includes } from "lodash";
import { getUpstreamUserInfo, getUpstremCohortId } from "./user-info";
import { getIdentity, getPortalInfo } from "../api/identity";
import { getCohortInfo } from "../api/cohort";
import { Auth } from "aws-amplify";
import { reportError } from "../api/bugsnag-logger";

type ExtantSession = {
  status: "SUCCESS";
  session: AppSession;
};

type NoSession = {
  status: "NO_SESSION";
};

export type SessionResponse = ExtantSession | NoSession;

export async function getSession(): Promise<SessionResponse> {
  try {
    const awsCredentials = await getCredentials();

    if (awsCredentials.authenticated) {
      const userInfo = await getUpstreamUserInfo();
      const identityId = (await getIdentity({ email: userInfo.email }))?.id;

      if (!isNil(identityId)) {
        const cohortId = await getUpstremCohortId();
        const cohort = await getCohortInfo({ cohortId });
        const role = await getPortalInfo({
          identityId,
          email: userInfo.email,
        })

        if (!isNil(cohort)) {
          return {
            status: "SUCCESS",
            session: {
              auth: { awsCredentials, identityId },
              user: userInfo,
              cohort,
              role: role?.role
            },
          };
        }
      }
    }
  } catch (e) {
    reportError("Error loading user session", e);
  }

  return { status: "NO_SESSION" };
}

export async function updatePlatforms(): Promise<any> {
  const userAttributes = await getUpstreamUserInfo();
  const currentPlatforms = userAttributes.platforms;

  if (!includes(currentPlatforms, "portal")) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const platformAttr = {
      Name: "custom:platforms",
      Value: `${currentPlatforms ?? ""} portal`.trim()
    };
    await cognitoUser.updateAttributes([platformAttr], (err: any, result: any) => {
      if (err) {
        reportError("Failed to update cognito user platform attributes", err);
      }
    });
  }
}

export async function updateChallengeOptIn(optIn: boolean): Promise<any> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const challengeOptInAttr = {
    Name: "custom:30dcOptIn",
    Value: optIn ? "true" : "false"
  }
  await cognitoUser.updateAttributes([challengeOptInAttr], (err: any, result: any) => {
    if (err) {
      reportError("Failed to update cognito user challenge opt in attributes", err);
    }
  });
}