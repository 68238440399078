import { Lens, Prism } from "@atomic-object/lenses";
import {
  AppState,
  ErrorState,
  EmailLoginErrors,
  UrlParams,
  InvitationErrors,
  InvitationStatus,
  SignupState,
  EmailConfirmationStatus,
  ConfirmationErrors,
  UserInfo,
  CohortInfo,
  AuthInfo,
  ResetPasswordState,
  ResetPasswordErrors,
  SignupErrors,
  ResetPasswordEmailState,
} from "./state";
import produce from "immer";

// Top level lenses
export const session = Lens.from<AppState>().prop("session");
export const errors = Lens.from<AppState>().prop("errors");
export const urlParams = Lens.from<AppState>().prop("urlParams");
export const loading = Lens.from<AppState>().prop("globalLoading");
export const signup = Lens.from<AppState>().prop("signup");
export const resetEmail = Lens.from<AppState>().prop("resetEmail");
export const reset = Lens.from<AppState>().prop("reset");
export const externalSurveySessions = Lens.from<AppState>().prop(
  "externalSurveySessions"
);

// Reset lenses
export const resetAccepted = reset.comp(
  Lens.from<ResetPasswordState>().prop("codeAccepted")
);
export const email = resetEmail.comp(
  Lens.from<ResetPasswordEmailState>().prop("email")
);

// Signup lenses
export const signupCohort = signup.comp(
  Lens.from<SignupState>().prop("cohort")
);
export const signupCreds = signup.comp(
  Lens.from<SignupState>().prop("credentials")
);

// Session prisms
export const userInfo = Prism.of<AppState, UserInfo | undefined>({
  get: (state) => state.session?.user,
  set: (state, user) =>
    user !== undefined && state.session !== undefined
      ? produce(state, (newState) => {
          newState.session = { ...state.session!, user };
        })
      : state,
});

export const cohortInfo = Prism.of<AppState, CohortInfo | undefined>({
  get: (state) => state.session?.cohort,
  set: (state, cohort) =>
    cohort !== undefined && state.session !== undefined
      ? produce(state, (newState) => {
          newState.session = { ...state.session!, cohort };
        })
      : state,
});

export const cohortInfoSignup = Prism.of<AppState, CohortInfo | undefined>({
  get: (state) => state.signup?.cohort,
  set: (state, cohort) =>
    cohort !== undefined && state.session !== undefined
      ? produce(state, (newState) => {
          newState.session = { ...state.session!, cohort };
        })
      : state,
});

export const authInfo = Prism.of<AppState, AuthInfo | undefined>({
  get: (state) => state.session?.auth,
  set: (state, auth) =>
    auth !== undefined && state.session !== undefined
      ? produce(state, (newState) => {
          newState.session = { ...state.session!, auth };
        })
      : state,
});

// Invite code lenses
export const invitationCode = signup.comp(
  Lens.from<SignupState>().prop("invitation")
);
export const inviteAccepted = invitationCode.comp(
  Lens.from<InvitationStatus>().prop("accepted")
);
export const inviteText = invitationCode.comp(
  Lens.from<InvitationStatus>().prop("code")
);

// Email Confirmation lenses
export const emailConfirmation = signup.comp(
  Lens.from<SignupState>().prop("confirmation")
);
export const confirmationAccepted = emailConfirmation.comp(
  Lens.from<EmailConfirmationStatus>().prop("accepted")
);
export const confirmationLoading = emailConfirmation.comp(
  Lens.from<EmailConfirmationStatus>().prop("loading")
);
export const confirmationUnconfirmed = emailConfirmation.comp(
  Lens.from<EmailConfirmationStatus>().prop("unconfirmed")
);
export const deferredCredentials = signup.comp(
  Lens.from<SignupState>().prop("credentials")
);

// Error state lenses
export const loginErrors = errors.comp(Lens.from<ErrorState>().prop("login"));
export const invitationErrors = errors.comp(
  Lens.from<ErrorState>().prop("invitation")
);
export const confirmationErrors = errors.comp(
  Lens.from<ErrorState>().prop("confirmation")
);
export const resetErrors = errors.comp(
  Lens.from<ErrorState>().prop("resetpassword")
);
export const signupErrors = errors.comp(Lens.from<ErrorState>().prop("signup"));

export const loginInvalidCredentials = loginErrors.comp(
  Lens.from<EmailLoginErrors>().prop("invalidCredentials")
);
export const loginUnknownError = loginErrors.comp(
  Lens.from<EmailLoginErrors>().prop("unknownError")
);

export const signupAlreadyExists = signupErrors.comp(
  Lens.from<SignupErrors>().prop("alreadyExists")
);
export const signupInvalidEmail = signupErrors.comp(
  Lens.from<SignupErrors>().prop("invalidEmail")
);
export const signupInvalidPassword = signupErrors.comp(
  Lens.from<SignupErrors>().prop("invalidPassword")
);

export const signupInvalidName = signupErrors.comp(
  Lens.from<SignupErrors>().prop("invalidName")
);
export const signupInvalidFirstName = signupErrors.comp(
  Lens.from<SignupErrors>().prop("invalidFirstName")
);
export const signupInvalidLastName = signupErrors.comp(
  Lens.from<SignupErrors>().prop("invalidLastName")
);
export const signupUnknownError = signupErrors.comp(
  Lens.from<SignupErrors>().prop("unknownError")
);

export const resetInvalidCode = resetErrors.comp(
  Lens.from<ResetPasswordErrors>().prop("invalidCode")
);
export const resetInvalidEmail = resetErrors.comp(
  Lens.from<ResetPasswordErrors>().prop("invalidEmail")
);
export const resetLimitExceeded = resetErrors.comp(
  Lens.from<ResetPasswordErrors>().prop("limitExceeded")
);
export const resetUnknownError = resetErrors.comp(
  Lens.from<ResetPasswordErrors>().prop("unknownError")
);
export const resetInvalidPassword = resetErrors.comp(
  Lens.from<ResetPasswordErrors>().prop("invalidPassword")
);

export const confirmationInvalidCode = confirmationErrors.comp(
  Lens.from<ConfirmationErrors>().prop("invalidCode")
);
export const confirmationInvalidCredentials = confirmationErrors.comp(
  Lens.from<ConfirmationErrors>().prop("invalidCredentials")
);
export const confirmationUnknownError = confirmationErrors.comp(
  Lens.from<ConfirmationErrors>().prop("unknownError")
);
export const invitationAlreadyClaimed = invitationErrors.comp(
  Lens.from<InvitationErrors>().prop("alreadyClaimed")
);

export const invitationInvalid = invitationErrors.comp(
  Lens.from<InvitationErrors>().prop("invalidCode")
);
export const invitationUnknownError = invitationErrors.comp(
  Lens.from<InvitationErrors>().prop("unknownError")
);

// URL param lenses
export const urlParticipantId = urlParams.comp(
  Lens.from<UrlParams>().prop("participantId")
);
export const urlAssignParticipant = urlParams.comp(
  Lens.from<UrlParams>().prop("assignParticipant")
);
export const urlEmail = urlParams.comp(Lens.from<UrlParams>().prop("email"));
export const urlName = urlParams.comp(Lens.from<UrlParams>().prop("name"));
export const urlSubCohort = urlParams.comp(
  Lens.from<UrlParams>().prop("subCohort")
);
