import { connect } from "../../store/connect";
import { InvitationCodeScreen } from "./invitation-code-screen";
import {
  session as sessionLens,
  loading as loadingLens,
  invitationErrors,
  inviteAccepted,
} from "../../store/lenses";

export const InvitationCodeScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
    codeAccepted: inviteAccepted(state),
    session: sessionLens(state),
    errors: invitationErrors(state),
  }),
  (dispatch) => ({
    submit: (code: string) => {
      console.log("CHECKING", code);
      dispatch({ type: "REQUEST_CHECK_INVITATION", code });
    },
  })
)(InvitationCodeScreen);
