import { connect } from "../../store/connect";
import { FrontDoorScreen } from "./front-door-screen";
import {
  loading as l,
  session as s,
  confirmationUnconfirmed,
} from "../../store/lenses";

export const FrontDoorScreenContainer = connect(
  (state) => ({
    loading: l(state),
    session: s(state),
    unconfirmed: confirmationUnconfirmed(state),
  }),
  (dispatch) => ({})
)(FrontDoorScreen);
