import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import { IconRefresh, IconRibbon } from "../../../components/icons";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
  KEY_T1_ACCESSED,
} from "../../../utils/storage";
import { openInCurrentTab } from "../../../util";
import "./styles.css";

type Props = {
  t1Completed: boolean;
  been30Days: boolean;
  t1DivRef: React.RefObject<HTMLInputElement>;
  t1SurveyLink: string;
  challengeOptedIn: boolean;
  challengeEnabled: boolean;
  optInBtnLoading: boolean;
  invalidEmail: string;
  optIn: () => void;
};

export const T1Section = (props: Props): JSX.Element => {
  const {
    t1DivRef,
    been30Days,
    t1Completed,
    t1SurveyLink,
    challengeOptedIn,
    challengeEnabled,
    optIn,
    optInBtnLoading,
    invalidEmail,
  } = props;
  const { t } = useTranslation();

  const handleOpeningT1Survey = () => {
    // Store this information to decide rendering refresh button
    setDataToLocalStorage(KEY_T1_ACCESSED, true);
    openInCurrentTab(t1SurveyLink);
  };
  const hasAccessedT1 = getDataFromLocalStorage(KEY_T1_ACCESSED);

  // Render refresh button after clicking t1 survey button
  const renderRefreshButton = () => {
    return hasAccessedT1 ? (
      <div className="survey-widget-info-t1-refresh">
        <span>{t("widgets.refreshDescription")}</span>
        <Button
          text={t("widgets.refreshButtonText")}
          onPress={() => {
            window.location.reload();
          }}
          buttonStyle="OUTLINE"
        >
          <IconRefresh />
        </Button>
      </div>
    ) : null;
  };

  const renderChallengeOptInSection = () => {
    if (t1Completed && challengeEnabled) {
      if (!challengeOptedIn) {
        return (
          <div className="survey-widget-info-t1-opt-in">
            <div className="horizontal-border" />
            <p className="widget-title">{t("widgets.optIntoChallengeTitle")}</p>
            <p className="survey-widget-description widget-description">
              {t("widgets.optIntoChallengeDescription")}
            </p>
            <Button
              text={t("widgets.optIntoChallengeButtonText")}
              onPress={optIn}
              buttonStyle="OPAQUE"
              disabled={optInBtnLoading}
            />
            {renderOptInError()}
          </div>
        );
      } else if (!been30Days) {
        return (
          <div className="survey-widget-info-t1-opt-in">
            <div className="horizontal-border" />
            <div className="survey-widget-info-t1-opted-in">
              <IconRibbon />
              <p className="survey-widget-description widget-description survey-widget-info-t1-opted-in-description">
                {t("widgets.optIntoChallengeDescriptionCompleted")}
              </p>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const renderOptInError = () => {
    return invalidEmail ? (
      <div className="error-invalid-email">
        <span>{`${invalidEmail} ${t("errors.invalidEmailZapier")}`}</span>
      </div>
    ) : null;
  };

  return (
    <div className="survey-widget-info-t1" ref={t1DivRef}>
      <span className="widget-title">{t("widgets.t1SurveyTitle")}</span>
      {!been30Days && (
        <span className="survey-widget-description widget-description">
          {t1Completed
            ? t("widgets.t1SurveyDescriptionCompleted")
            : t("widgets.t1SurveyDescription")}
        </span>
      )}
      {!t1Completed && (
        <>
          <Button
            text={t("widgets.t1SurveyButtonText")}
            onPress={handleOpeningT1Survey}
            buttonStyle="OPAQUE"
          />
          {renderRefreshButton()}
        </>
      )}
      {renderChallengeOptInSection()}
    </div>
  );
};
