import * as GQLTypes from "@hminnovations/graphql-types";
import gql from "graphql-tag";
import { buildGqlClient, uuidv4 } from "../util";
import { mutate, query } from "./gql";

interface CreateIdentityResult {
  createIdentity: GQLTypes.Identity | null;
}

interface GetIdentityResult {
  getIdentity: GQLTypes.Identity | null;
}

interface GetPortalInfoResult {
  getPortalInfo: GQLTypes.PortalInfo | null;
}

const CreateIdentityMutation = gql`
  mutation CreateIdentity($creds: IdentityCreds!, $input: IdentityInput) {
    createIdentity(creds: $creds, input: $input) {
      id
      email
      cognitoId
      inviteCode
      orderId
      startDate
      participantId
      role
    }
  }
`;

const GetIdentityQuery = gql`
  query GetIdentity($creds: IdentityCreds!) {
    getIdentity(creds: $creds) {
      id
      email
      cognitoId
      inviteCode
      orderId
      startDate
      participantId
      role
    }
  }
`;

const GetPortalInfoQuery = gql`
  query GetPortalInfo($creds: IdentityCreds!) {
    getPortalInfo(creds: $creds) {
      identity {
        id
        email
      }
      role {
        id
        title
      }
      widgets {
        id
        title
      }
    }
  }
`;

export const createIdentity = async (
  email: string,
  input: GQLTypes.IdentityInput
): Promise<GQLTypes.Identity | undefined> => {
  const identity = await getIdentity({
    email,
  });

  const creds = {
    email,
    identityId: identity && identity.id ? identity.id : uuidv4(),
  };

  const client = await buildGqlClient();
  const res = await mutate<
    CreateIdentityResult,
    GQLTypes.CreateIdentityMutationArgs
  >(CreateIdentityMutation, { creds, input }, client);

  return res && res.createIdentity ? res.createIdentity : undefined;
};

export const getIdentity = async (
  creds: GQLTypes.IdentityCreds
): Promise<GQLTypes.Identity | undefined> => {
  const client = await buildGqlClient();
  const res = await query<GetIdentityResult, GQLTypes.GetIdentityQueryArgs>(
    GetIdentityQuery,
    { creds },
    client
  );

  return res && res.getIdentity ? res.getIdentity : undefined;
};

export const getPortalInfo = async (
  creds: GQLTypes.IdentityCreds
): Promise<GQLTypes.PortalInfo | undefined> => {
  const client = await buildGqlClient();
  const res = await query<GetPortalInfoResult, GQLTypes.GetPortalInfoQueryArgs>(
    GetPortalInfoQuery,
    { creds },
    client
  );

  return res && res.getPortalInfo ? res.getPortalInfo : undefined;
};
