import { AppReducer } from ".";
import {
  confirmationLoading,
  confirmationAccepted,
  confirmationInvalidCode,
  confirmationUnknownError,
  confirmationErrors,
  session,
  confirmationInvalidCredentials,
  signupCreds,
  confirmationUnconfirmed,
} from "../lenses";
import { flow } from "lodash";
import { initialConfirmationErrors } from "../state";
import { handleEmailChannel } from "../../api/onesignal";

export const confirmationReducer: AppReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_CONFIRM_EMAIL":
      return confirmationLoading.set(true)(state);
    case "CONFIRM_EMAIL_RESPONSE":
      const finishConfirmLoading = confirmationLoading.set(false)(state);

      if (action.response.status === "SUCCESS") {
        handleEmailChannel(action.response.newSession);
        return flow(
          confirmationErrors.set(initialConfirmationErrors()),
          session.set(action.response.newSession),
          confirmationAccepted.set(true)
        )(finishConfirmLoading);
      } else if (action.response.invalidCode === true) {
        return confirmationInvalidCode.set(true)(finishConfirmLoading);
      } else if (action.response.invalidCredentials === true) {
        return flow(
          signupCreds.set(undefined),
          confirmationUnconfirmed.set(false),
          confirmationInvalidCredentials.set(true)
        )(finishConfirmLoading);
      }

      return confirmationUnknownError.set(true)(finishConfirmLoading);
    default:
      return state;
  }
};
