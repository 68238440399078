export const en = {
  translation: {
    title: "Healthy Minds Program",
    invitationCode: {
      title: "Invitation Code",
      submit: "Submit",
    },
    login: {
      title: "Log in",
      already: "Already have an account?",
      forgotPassword: "Forgot your password?",
    },
    resetPassword: {
      instruction: "We'll email you a code to reset your password",
      accountEmail: "Enter your account email",
      success: "Success!",
      codeSent: {
        part1: "A password code was sent to:",
        part2: "Please create a new password below:",
      },
    },
    logout: {
      title: "Log out",
    },
    createAccount: {
      headerText:
        "Create an account to access the Healthy Minds Program and save your progress.",
      submit: "Create an Account",
      healthWarning:
        "Please note that this program is not a substitute for professional mental health treatment and does not diagnose or treat illness. If you have questions, consult with your mental health provider before beginning the program.",
    },
    frontDoor: {
      signUp: "Sign Up",
      login: "Login",
    },
    errors: {
      invalidEmailOrPassword: "Invalid email or code",
      invalidEmailAddress: "Invalid email address",
      invalidConfirmationCode: "Invalid confirmation code",
      invalidCredentials: "Please enter a valid username and password",
      genericError:
        "Something went wrong. Please try again, and contact support@hminnovations.org if the problem continues.",
      extantEmail: "Account already exists",
      invalidEmailZapier:
        "is an invalid email address for the Challenge. Please contact support@hminnovations.org.",
      slowDown:
        "Someone (possibly you) has tried to reset the password of this account several times " +
        "in a row. To protect your account, we've stopped accepting password reset attempts for " +
        "a while. Please try again later.",
      pageNotFound:
        "The page you requested is not available. Please contact support@hminnovations.org if you need assistance.",
    },
    verifyEmail: {
      newCodeSent: "A new code has been sent",
      invalidCode: "Invalid code",
      almostThere: "Almost done!",
      sentTo: "We sent a 6-digit confirmation code to:",
      confirmationCode: "Confirmation code",
      resendCode: "Re-send Code",
      submit: "Submit",
      newAccount: "If this email is incorrect, ",
      newAccountLink: "create a new account",
      spamWarning:
        "Didn't receive the email? Check your spam folder for emails from ",
      spamWarningEmail: "hello@hminnovations.org",
      needHelp: "Need help?",
    },
    verifySuccess: {
      headerText: "Email confirmed!",
      cta: "Install the Healthy Minds Program to get started!",
      appleStore: "Install on the Apple App Store",
      googlePlay: "Install on Google Play",
    },
    inputs: {
      name: "Full Name",
      email: "Email",
      emailConfirmation: "",
      password: "Password",
      passwordNew: "New password",
      passwordHelp:
        "At least 8 characters, with 1 number and 1 lowercase letter (no spaces allowed)",
      firstName: "First Name",
      lastName: "Last Name",
    },
    terms: {
      tos: "I accept the ",
      tosLink: "Terms of Service",
      privacy: "I have read and understand the ",
      privacyLink: "Privacy Policy",
    },
    widgets: {
      downloadTitle: "Download the app",
      downloadDescription:
        "You've already created an account, simply download and log in to the app.",
      downloadAppStore: "Download on the App Store",
      downloadPlayStore: "Download on Google Play",
      emailConfirmedTitle: "Email address confirmed",
      emailConfirmedDescription:
        "This will be the email associated with your Healthy Minds Program account.",
      t1SurveyTitle: "Well-being assessment",
      t1SurveyDescription:
        "Take your first well-being assessment, then start the 30 Day Challenge in the Healthy Minds Program mobile app.",
      t1SurveyDescriptionCompleted:
        "You've completed your initial workplace well-being assessment your scores have been emailed to you.",
      t1SurveyButtonText: "Take the assessment",
      t2SurveyTitle: "Second assessment",
      t2SurveyDescription:
        "After 30 days, come back here to take the second assessment and see how you've changed.",
      t2SurveyDescriptionAfter30Days:
        "It’s been 30 days since your first assessment, take a moment to see how you’ve changed.",
      t2SurveyButtonText: "Take the second assessment",
      refreshDescription:
        "Click to refresh if you've already taken the assessment",
      refreshButtonText: "Refresh",
      optIntoChallengeTitle: "Sign up for the 30 Day Challenge",
      optIntoChallengeDescription:
        "Kick off your first month in the app with an email once per week from a Healthy Minds Trainer, including tips and videos to deepen your knowledge of well-being.",
      optIntoChallengeDescriptionCompleted:
        "You are signed up for the 30 Day Challenge! You will receive weekly email reminders for four weeks.",
      optIntoChallengeButtonText: "Sign me up",
    },
    deepLinkPage: {
      title: "You'll need to view this link on your mobile device",
      description:
        "Scan the QR code with your mobile device's camera to open or download the app.",
    },
  },
};
