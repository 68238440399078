import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  WithSession,
  hasActiveSession,
  contentOrLoading,
  openInNewTab,
} from "../../util";
import { APP_ROUTES } from "../../router";
import { Button } from "../../components/button";
import { useTranslation } from "react-i18next";
import { HmiLogo } from "../../components/hmi-logo";
import { getWidgetsForSession } from "../../widgets/utils";
import { IconOvals } from "../../components/icons";
import { LogoutButtonContainer } from "../../components/logout-button/logout-button-container";
import { ExternalSurveySession, CohortInfo } from "../../store/state";

import "./home-screen.css";

export type HomeScreenProps = {
  loading: boolean;
  cohortInfo?: CohortInfo;
  externalSurveySessions?: ExternalSurveySession[];
  getExternalSurveySessions: () => void;
} & WithSession;

const HELP_LINK =
  "https://hminnovations.zendesk.com/hc/en-us/articles/360058449472-HMP-Web-Help";

export function HomeScreen(props: HomeScreenProps) {
  const { getExternalSurveySessions } = props;
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getExternalSurveySessions();
  }, [getExternalSurveySessions]);

  if (!hasActiveSession(props)) {
    const frontDoor = APP_ROUTES.frontDoor.toPath();
    history.push(frontDoor);
  }

  const userWidgets = getWidgetsForSession(
    props.externalSurveySessions ?? [],
    !!props.cohortInfo?.t1SurveyURL
  );

  const homeScreen = (
    <div className="home-screen">
      <IconOvals />
      <div className="home-screen-wrap">
        <div className="home-screen-logo-wrap">
          <HmiLogo />
        </div>
        <div className="widget-container">{userWidgets}</div>
        <Button
          id="btn-need-help"
          buttonStyle="TEXT"
          text={t("verifyEmail.needHelp")}
          onPress={() => openInNewTab(HELP_LINK)}
        />
        <LogoutButtonContainer />
      </div>
    </div>
  );

  return contentOrLoading(homeScreen, "home-screen", props.loading);
}
