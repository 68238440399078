import { AppReducer } from ".";
import { initialErrorState } from "../state";
import {
  loading,
  errors,
  confirmationUnconfirmed,
  signupCreds,
  signupAlreadyExists,
  signupInvalidEmail,
  signupInvalidPassword,
  signupUnknownError,
  deferredCredentials,
  session,
  signupInvalidName,
  signupInvalidFirstName,
  signupInvalidLastName,
} from "../lenses";
import { flow } from "lodash";

export const signupReducer: AppReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_SIGNUP":
      return loading.set(true)(state);
    case "SIGNUP_RESPONSE":
      if (action.response.status === "SUCCESS") {
        return flow(
          loading.set(false),
          errors.set(initialErrorState()),
          signupCreds.set({
            email: action.response.email,
            password: action.response.password,
          }),
          confirmationUnconfirmed.set(true)
        )(state);
      }

      switch (action.response.error) {
        case "VALIDATION":
          return flow(
            loading.set(false),
            signupInvalidPassword.set(!action.response.passwordIsValid),
            signupInvalidEmail.set(!action.response.emailIsValid),
            signupInvalidName.set(!action.response.nameIsValid),
            signupInvalidFirstName.set(!action.response.firstNameIsValid),
            signupInvalidLastName.set(!action.response.lastNameIsValid)
          )(state);
        case "ALREADY_EXISTS":
          return flow(loading.set(false), signupAlreadyExists.set(true))(state);
        case "INVALID_EMAIL":
          return flow(loading.set(false), signupInvalidEmail.set(true))(state);
        case "INVALID_PASSWORD":
          return flow(
            loading.set(false),
            signupInvalidPassword.set(true)
          )(state);
        case "UNCONFIRMED":
          const storedCreds = {
            email: action.response.email,
            password: action.response.password,
          };
          return flow(
            session.set(undefined),
            loading.set(false),
            deferredCredentials.set(storedCreds),
            confirmationUnconfirmed.set(true)
          )(state);
      }

      return flow(loading.set(false), signupUnknownError.set(true))(state);
    case "CLEAR_SIGNUP_CREDS":
      return flow(
        signupCreds.set(undefined),
        confirmationUnconfirmed.set(false)
      )(state);
    default:
      return state;
  }
};
