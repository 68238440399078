import { createMuiTheme } from "@material-ui/core";

export const defaultTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#56fdd2",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ff0048",
    },
  },
  overrides: {
    MuiTextField: {},
  },
});
