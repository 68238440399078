import React from "react";
import { LoadingSpinner } from "../../components/loading-spinner";
import { useParams, Redirect, useLocation } from "react-router-dom";
import {
  WithSession,
  hasActiveSession,
  parseLegacyUrlParams,
  parseUrlParams,
} from "../../util";
import { APP_ROUTES } from "../../router";
import { isNil } from "lodash";
import { UrlParams } from "../../store/state";
import "./landing-screen.css";
import { InvitationErrors } from "../../store/state";

export type LandingScreenProps = {
  loading: boolean;
  invitationAccepted: boolean;
  invitationErrors: InvitationErrors;
  submitCode: (code: string) => void;
  setParams: (params: UrlParams) => void;
} & WithSession;

const codeLooksLegit = (invitationCode: any) => {
  const codeIsNullish =
    isNil(invitationCode) ||
    invitationCode === "" ||
    invitationCode === "undefined";
  return typeof invitationCode === "string" && !codeIsNullish;
};

export function LandingScreen(props: LandingScreenProps) {
  const { submitCode, invitationAccepted, invitationErrors, setParams } = props;
  const { invitationCode } = useParams();
  const location = useLocation();

  React.useEffect(() => {
    let inviteCode,
      participantId,
      subCohort = "";

    // Fall back to using the legacy way of parsing url params if invitationCode includes '&' instead of '?'
    const isLegacyParams = invitationCode.includes("&");
    if (isLegacyParams) {
      ({ inviteCode, participantId, subCohort } =
        parseLegacyUrlParams(invitationCode));
    } else {
      ({ inviteCode, participantId, subCohort } = parseUrlParams(
        invitationCode,
        location.search
      ));
    }

    if (codeLooksLegit(inviteCode)) {
      const decodedCode = decodeURIComponent(inviteCode);
      console.log("Submitting code.", decodedCode);

      submitCode(decodedCode);

      setParams({
        participantId: codeLooksLegit(participantId)
          ? participantId
          : undefined,
        subCohort: codeLooksLegit(subCohort) ? subCohort : undefined,
      });
    }
  }, [invitationCode, submitCode, setParams, location.search]);

  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    return <Redirect to={homePath} />;
  }

  if (invitationAccepted) {
    const signupPath = APP_ROUTES.signup.toPath();
    return <Redirect to={signupPath} />;
  }

  if (
    invitationErrors.alreadyClaimed ||
    invitationErrors.invalidCode ||
    invitationErrors.unknownError
  ) {
    const errorScreenPath = APP_ROUTES.errorScreen.toPath();
    return <Redirect to={errorScreenPath} />;
  }

  return <LoadingSpinner wrapperClass="landing-screen" />;
}
