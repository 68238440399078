import { AppReducer } from ".";
import {
  loading,
  resetErrors,
  resetAccepted,
  resetInvalidCode,
  resetUnknownError,
  email,
  resetInvalidEmail,
  resetLimitExceeded,
  resetInvalidPassword,
  resetEmail,
  errors,
} from "../lenses";
import { flow } from "lodash";
import {
  initialResetPasswordErrors,
  initialResetPasswordEmailState,
  initialErrorState,
} from "../state";

export const resetReducer: AppReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_RESET_PASSWORD":
    case "REQUEST_RESET_PASSWORD_SUBMIT":
      return loading.set(true)(state);
    case "CLEAR_RESET_EMAIL":
      return flow(
        errors.set(initialErrorState()),
        resetEmail.set(initialResetPasswordEmailState())
      )(state);
    case "RESET_PASSWORD_RESPONSE":
      if (action.response.status === "SUCCESS") {
        return flow(
          loading.set(false),
          resetErrors.set(initialResetPasswordErrors()),
          email.set(action.response.email)
        )(state);
      }
      if (
        action.response.error.type === "INVALID_EMAIL" ||
        action.response.error.type === "USER_NOT_FOUND" ||
        action.response.error.type === "USER_UNCONFIRMED" ||
        action.response.error.type === "USER_DISABLED"
      ) {
        return flow(
          loading.set(false),
          resetInvalidEmail.set(true),
          email.set(undefined)
        )(state);
      }

      if (action.response.error.type === "LIMIT_EXCEEDED") {
        return flow(loading.set(false), resetLimitExceeded.set(true))(state);
      }

      return flow(loading.set(false), resetUnknownError.set(true))(state);
    case "RESET_PASSWORD_SUBMIT_RESPONSE":
      if (action.response.status === "RESET_PASSWORD_SUCCESS") {
        return flow(
          loading.set(false),
          resetErrors.set(initialResetPasswordErrors()),
          resetAccepted.set(true)
        )(state);
      }
      const errorType = action.response.error.type;
      return flow(
        loading.set(false),
        resetInvalidCode.set(errorType === "INVALID_CODE"),
        resetInvalidPassword.set(errorType === "INVALID_PASSWORD"),
        resetLimitExceeded.set(errorType === "LIMIT_EXCEEDED"),
        resetUnknownError.set(errorType === "UNKNOWN"),
        resetAccepted.set(false)
      )(state);

    default:
      return state;
  }
};
