import { connect } from "../../store/connect";
import {
  cohortInfo,
  userInfo,
  externalSurveySessions,
} from "../../store/lenses";
import { SurveyWidget } from "./survey";

export const SurveyWidgetContainer = connect(
  (state) => ({
    userInfo: userInfo(state),
    cohortInfo: cohortInfo(state),
    externalSurveySessions: externalSurveySessions(state),
  }),
  (dispatch) => ({})
)(SurveyWidget);
