import { connect } from "../../store/connect";
import { ResetPasswordScreen } from "./reset-password-screen";
import {
  loading as loadingLens,
  session as sessionLens,
  resetErrors,
  email,
} from "../../store/lenses";

export const ResetPasswordScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
    session: sessionLens(state),
    errors: resetErrors(state),
    email: email(state),
  }),
  (dispatch) => ({
    submitCodeAndPassword: (
      email: string,
      code: string,
      newPassword: string
    ) => {
      dispatch({
        type: "REQUEST_RESET_PASSWORD_SUBMIT",
        email,
        code,
        newPassword,
      });
    },
  })
)(ResetPasswordScreen);
