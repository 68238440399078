import React from "react";
import "./button.css";

export type ButtonProps = {
  id?: string;
  text: string;
  buttonStyle: "OPAQUE" | "TEXT" | "OUTLINE";
  disabled?: boolean;
  onPress: () => any;
} & React.ComponentPropsWithRef<"button">;

export function Button(props: ButtonProps) {
  const { text, buttonStyle, disabled, onPress, id, children } = props;
  const buttonClass =
    buttonStyle === "OPAQUE"
      ? "solid-button anim"
      : buttonStyle === "OUTLINE"
      ? "outline-button anim"
      : "transparent-button";

  return (
    <button
      id={id}
      className={`app-button ${buttonClass}`}
      disabled={disabled}
      onClick={onPress}
    >
      {children}
      <span>{text}</span>
    </button>
  );
}
