import { AppReducer } from ".";
import { initialErrorState, initialAppState } from "../state";
import {
  loading,
  errors,
  session,
  deferredCredentials,
  confirmationUnconfirmed,
  loginInvalidCredentials,
  loginUnknownError,
} from "../lenses";
import { flow } from "lodash";
import { handleEmailChannel, handleLogoutOneSignal } from "../../api/onesignal";

export const loginReducer: AppReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
    case "REQUEST_LOGOUT":
      if (state.session) {
        handleLogoutOneSignal(state.session);
      }
      return loading.set(true)(state);
    case "LOGIN_RESPONSE":
      if (action.response.status === "SUCCESS") {
        handleEmailChannel(action.response.newSession);
        return flow(
          errors.set(initialErrorState()),
          session.set(action.response.newSession)
        )(state);
      }

      switch (action.response.error.type) {
        case "BAD_CREDENTIALS":
          return flow(
            session.set(undefined),
            loading.set(false),
            loginInvalidCredentials.set(true)
          )(state);
        case "UNCONFIRMED":
          const storedCreds = {
            email: action.response.error.email,
            password: action.response.error.password,
          };
          return flow(
            session.set(undefined),
            loading.set(false),
            deferredCredentials.set(storedCreds),
            confirmationUnconfirmed.set(true)
          )(state);
        case "UNKNOWN":
          return flow(
            session.set(undefined),
            loading.set(false),
            loginInvalidCredentials.set(false),
            loginUnknownError.set(true)
          )(state);
        default:
          return flow(loading.set(false), session.set(undefined))(state);
      }
    case "LOGOUT_RESPONSE":
      return loading.set(false)(initialAppState());
    default:
      return state;
  }
};
