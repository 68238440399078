import { UserInfo, CohortInfo } from "../store/state";
import { Auth } from "aws-amplify";
import { isNil } from "lodash";
import { IdentityInput, UserInfoInput } from "@hminnovations/graphql-types";

export async function getUpstreamUserInfo(): Promise<UserInfo> {
  const rawInfo = await Auth.currentUserInfo();
  const { attributes } = rawInfo;

  if (
    !isNil(attributes) &&
    !isNil(attributes["name"]) &&
    !isNil(attributes["email"])
  ) {
    return {
      name: attributes["name"],
      email: attributes["email"],
      participantId: attributes["custom:participantId"],
      platforms: attributes["custom:platforms"],
      challengeOptIn: attributes["custom:30dcOptIn"],
      subCohort: attributes["custom:subCohort"],
    };
  }

  throw new Error(`Couldnt get user info, got ${rawInfo}`);
}

export async function getUpstremCohortId(): Promise<CohortInfo["id"]> {
  const rawInfo = await Auth.currentUserInfo();
  const { attributes } = rawInfo;

  if (!isNil(attributes) && !isNil(attributes["custom:cohortId"])) {
    return attributes["custom:cohortId"];
  }

  throw new Error(`Couldnt get cohort ID, got ${rawInfo}`);
}

export async function getIdentityInputForCognitoUser(
  cognitoId: string
): Promise<IdentityInput> {
  const rawInfo = await Auth.currentUserInfo();
  const { attributes } = rawInfo;

  return {
    cognitoId,
    participantId: attributes["custom:participantId"],
    inviteCode: attributes["custom:invitationCode"],
  };
}

export async function getUserInfoInputForCognitoUser(): Promise<UserInfoInput> {
  const rawInfo = await Auth.currentUserInfo();
  const { attributes } = rawInfo;

  return {
    email: attributes["email"],
    cohortName: attributes["custom:cohortName"],
    invitationCode: attributes["custom:invitationCode"],
    participantId: attributes["custom:participantId"],
    name: attributes["name"],
  };
}
