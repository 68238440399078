import { processKeyWithDefault } from "./util";
import { dev } from "./aws/dev";
import { prod } from "./aws/prod";

const isDev = processKeyWithDefault("REACT_APP_IS_DEV", "true");

export const RUNNING_IN_DEV = isDev === "true";
// export const RUNNING_IN_DEV = false;

export const AWS_ENV = RUNNING_IN_DEV ? dev : prod;

export const AWS_AMP_CONFIG = {
  identityPoolId: AWS_ENV.identityPoolId,
  region: AWS_ENV.region,
  userPoolId: AWS_ENV.userPoolId,
  userPoolWebClientId: AWS_ENV.userPoolWebClientId,
  mandaytorySignIn: false,
};

if (RUNNING_IN_DEV) {
  console.log("App is running in development with config:", {
    AWS_ENV,
    AWS_AMP_CONFIG,
    RUNNING_IN_DEV,
  });
}

export const BUGSNAG_API_KEY = processKeyWithDefault(
  "REACT_APP_BUGSNAG_API_KEY",
  ""
);
