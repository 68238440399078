import { AwsConfig } from ".";

export const dev: AwsConfig = {
  region: "us-east-2",
  gqlApi:
    "https://c3za5y46jrhtpj5uwidclywoa4.appsync-api.us-east-2.amazonaws.com/graphql",
  identityPoolId: "us-east-2:a6366c23-35c0-4e07-8463-25d31366ef51",
  userPoolId: "us-east-2_YxLjMP78G",
  userPoolWebClientId: "2uh5k82hfketvbd8r2defh37jv",
  invitationHandlerLambda: "tf-dev-graphql-invitation-handler",
  zapierHandlerLambda: "tf-dev-zapier-handler",
};
