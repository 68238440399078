import { InvitationCodeCreds } from "@hminnovations/graphql-types";
import { getCredentials } from "./user";
import { AsyncReturnType } from "../util";
import AWS from ".";
import { isNil } from "lodash";
import { AWS_ENV } from "../environment";
import { CohortInfo } from "../store/state";
import { reportError } from "./bugsnag-logger";

export type InvitationLambdaPayload = {
  creds: InvitationCodeCreds;
  operation: "check" | "consume";
  iamKey: AsyncReturnType<typeof getCredentials>;
};

type InvitationCodeValidationResponse = {
  code: string;
  cohort: string;
  cohortId: string;
  cohortLogoUri: string;
  cohortProgramUri: string;
  status: "UNCLAIMED" | "ALREADY_CLAIMED" | "INVALID";
  errorMessage?: string;
  cohortMicrosupportEnabled?: boolean;
};

type InvitationSuccess = {
  status: "SUCCESS";
  code: string;
  cohort: CohortInfo;
};

type InvitationFailure = {
  status: "ERROR";
  error: "ALREADY_CLAIMED" | "INVALID" | "UNKNOWN";
};

export type InvitationResponse = InvitationSuccess | InvitationFailure;

function createLambda(credentials: AsyncReturnType<typeof getCredentials>) {
  AWS.config.update({ credentials });

  return new AWS.Lambda({ region: AWS_ENV.region });
}

async function makeInvitationCodeRequest(
  code: string,
  operation: InvitationLambdaPayload["operation"],
  creds?: InvitationLambdaPayload["iamKey"]
): Promise<InvitationLambdaPayload> {
  const iamKey = isNil(creds) ? await getCredentials() : creds;

  return { creds: { code }, operation, iamKey };
}

export async function operateForInvitationCode(
  code: string,
  operation: InvitationLambdaPayload["operation"]
): Promise<InvitationResponse> {
  const awsCredentials = await getCredentials();
  const lambda = createLambda(awsCredentials);
  const lambdaPayload = await makeInvitationCodeRequest(
    code,
    operation,
    awsCredentials
  );

  // Invoke invite lambda
  const raw = await lambda
    .invoke({
      FunctionName: AWS_ENV.invitationHandlerLambda,
      Payload: JSON.stringify(lambdaPayload),
    })
    .promise();

  const res: InvitationCodeValidationResponse = JSON.parse(
    raw.Payload as string
  );

  if (!isNil(res.errorMessage)) {
    reportError(`Error ${operation}ing invite code: ${code}`, res.errorMessage);
    return { status: "ERROR", error: "UNKNOWN" };
  }

  switch (res.status) {
    case "UNCLAIMED":
      return {
        status: "SUCCESS",
        code,
        cohort: {
          id: res.cohortId,
          name: res.cohort,
          uri: res.cohortLogoUri,
          path: res.cohortProgramUri,
          microsupportEnabled: res.cohortMicrosupportEnabled,
        },
      };
    case "ALREADY_CLAIMED":
    case "INVALID":
      return { status: "ERROR", error: res.status };
  }
}
