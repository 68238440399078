import { AsyncReturnType, buildGqlClient } from "../util";

export async function mutate<OutputType, ArgType>(
  mutation: any,
  variables: ArgType,
  client: AsyncReturnType<typeof buildGqlClient>
): Promise<OutputType | undefined> {
  const res = await client.mutate<OutputType, ArgType>({ mutation, variables });
  return res.data as OutputType;
}

export async function query<OutputType, ArgType>(
  query: any,
  variables: ArgType,
  client: AsyncReturnType<typeof buildGqlClient>
): Promise<OutputType | undefined> {
  const res = await client.query<OutputType, ArgType>({
    query,
    variables,
    fetchPolicy: "network-only",
  });
  return res.data;
}
