import React from "react";
import { ReactComponent as OvalLeftIcon1 } from "../../../assets/icon-oval-left-1.svg";
import { ReactComponent as OvalLeftIcon2 } from "../../../assets/icon-oval-left-2.svg";
import { ReactComponent as OvalRightIcon1 } from "../../../assets/icon-oval-right-1.svg";

import "./styles.css";

export const IconOvals = () => {
  return (
    <div className="icon-ovals">
      <OvalLeftIcon1 className="icon-oval-left-1 icon-oval" />
      <OvalLeftIcon2 className="icon-oval-left-2 icon-oval" />
      <OvalRightIcon1 className="icon-oval-right-1 icon-oval" />
    </div>
  );
};
