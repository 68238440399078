import { Auth } from "aws-amplify";
import { isNil } from "lodash";
import { reportError, reportWarning } from "../api/bugsnag-logger";
import { normalizeEmail } from "../util";

type ResetPasswordSuccess = {
  status: "RESET_PASSWORD_SUCCESS";
  email: string;
  newPassword: string;
};

type InvalidEmail = {
  type: "INVALID_EMAIL";
};

type InvalidCode = {
  type: "INVALID_CODE";
};

type InvalidPassword = {
  type: "INVALID_PASSWORD";
};

type LimitExceededException = {
  type: "LIMIT_EXCEEDED";
};

type UserNotFoundException = {
  type: "USER_NOT_FOUND";
};

type UserUnconfirmed = {
  type: "USER_UNCONFIRMED";
};

type UserDisabled = {
  type: "USER_DISABLED";
};

type Unknown = {
  type: "UNKNOWN";
};

type ResetPasswordFailure = {
  status: "ERROR";
  error: InvalidCode | LimitExceededException | InvalidPassword | Unknown;
};

export type ResetPasswordResponse = ResetPasswordSuccess | ResetPasswordFailure;

type ForgotPasswordSuccess = {
  status: "SUCCESS";
  email: string;
};

type ForgotPasswordFailure = {
  status: "ERROR";
  error:
    | InvalidEmail
    | Unknown
    | LimitExceededException
    | UserNotFoundException
    | UserUnconfirmed
    | UserDisabled;
};

export type ForgotPasswordResponse =
  | ForgotPasswordSuccess
  | ForgotPasswordFailure;

export async function forgotPassword(
  username: string
): Promise<ForgotPasswordResponse> {
  const safeUsername = normalizeEmail(username);
  try {
    await Auth.forgotPassword(safeUsername);
    return {
      status: "SUCCESS",
      email: safeUsername,
    };
  } catch (e) {
    if (!isNil(e.code) && e.code === "LimitExceededException") {
      reportWarning(`Limit exceeded for resetting password`, {
        email: safeUsername,
      });
      return { status: "ERROR", error: { type: "LIMIT_EXCEEDED" } };
    }
    if (!isNil(e.code) && e.code === "UserNotFoundException") {
      reportWarning(`User not found resetting password`, {
        email: safeUsername,
      });
      return { status: "ERROR", error: { type: "USER_NOT_FOUND" } };
    }
    if (
      !isNil(e.code) &&
      e.code === "InvalidParameterException" &&
      !isNil(e.message) &&
      typeof e.message === "string" &&
      e.message.includes("no registered/verified email")
    ) {
      reportWarning(`User is unconfirmed resetting password`, {
        email: safeUsername,
      });
      return { status: "ERROR", error: { type: "USER_UNCONFIRMED" } };
    }
    if (
      !isNil(e.code) &&
      e.code === "NotAuthorizedException" &&
      !isNil(e.message) &&
      typeof e.message === "string" &&
      e.message.includes("User is disabled.")
    ) {
      reportWarning(`User is disabled resetting password`, {
        email: safeUsername,
      });
      return { status: "ERROR", error: { type: "USER_DISABLED" } };
    }
    reportError("Error requesting password reset", e);
  }

  return { status: "ERROR", error: { type: "UNKNOWN" } };
}

export async function resetPassword(
  username: string,
  code: string,
  password: string
): Promise<ResetPasswordResponse> {
  const safeUsername = normalizeEmail(username);
  try {
    await Auth.forgotPasswordSubmit(safeUsername, code, password);
    return {
      status: "RESET_PASSWORD_SUCCESS",
      email: safeUsername,
      newPassword: password,
    };
  } catch (e) {
    if (
      (!isNil(e.code) && e.code === "CodeMismatchException") ||
      e.code === "ExpiredCodeException"
    ) {
      reportWarning(
        `Accepted wrong confirmation code while resetting password`,
        {
          email: safeUsername,
          code,
        }
      );
      return { status: "ERROR", error: { type: "INVALID_CODE" } };
    }
    if (!isNil(e.code) && e.code === "InvalidPasswordException") {
      reportWarning(`Invalid new password`, {
        email: safeUsername,
      });
      return { status: "ERROR", error: { type: "INVALID_PASSWORD" } };
    }

    if (!isNil(e.code) && e.code === "LimitExceededException") {
      reportWarning(`Limit exceeded for resetting password`, {
        email: safeUsername,
        code,
      });
      return { status: "ERROR", error: { type: "LIMIT_EXCEEDED" } };
    }
    reportError("Error submitting password reset", e);
  }
  return { status: "ERROR", error: { type: "UNKNOWN" } };
}
