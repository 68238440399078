import { AppReducer } from ".";
import { AppState } from "../state";
import {
  CheckInvitationResponse,
  ConsumeInvitationResponse,
  RequestCheckInvitation,
  RequestConsumeInvitation,
} from "../actions";
import { flow } from "lodash";
import {
  inviteAccepted,
  invitationAlreadyClaimed,
  invitationInvalid,
  invitationUnknownError,
  signupCohort,
  loading,
  inviteText,
} from "../lenses";

const handleCheckOrConsume = (
  state: AppState,
  action: CheckInvitationResponse | ConsumeInvitationResponse
): AppState => {
  if (action.response.status === "SUCCESS") {
    return flow(
      loading.set(false),
      inviteAccepted.set(true),
      signupCohort.set(action.response.cohort)
    )(state);
  }

  switch (action.response.error) {
    case "ALREADY_CLAIMED":
      return flow(
        loading.set(false),
        inviteAccepted.set(false),
        invitationAlreadyClaimed.set(true)
      )(state);
    case "INVALID":
      return flow(
        loading.set(false),
        inviteAccepted.set(false),
        invitationInvalid.set(true)
      )(state);
    default:
      return flow(
        loading.set(false),
        inviteAccepted.set(false),
        invitationUnknownError.set(true)
      )(state);
  }
};

const requestCheckOrConsume = (
  state: AppState,
  action: RequestCheckInvitation | RequestConsumeInvitation
): AppState => {
  return flow(
    loading.set(true),
    invitationUnknownError.set(false),
    invitationAlreadyClaimed.set(false),
    invitationInvalid.set(false),
    inviteText.set(action.code)
  )(state);
};

export const invitationCodeReducer: AppReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_CHECK_INVITATION":
    case "REQUEST_CONSUME_INVITATION":
      return requestCheckOrConsume(state, action);
    case "CHECK_INVITATION_RESPONSE":
    case "CONSUME_INVITATION_RESPONSE":
      return handleCheckOrConsume(state, action);
    case "CLEAR_INVITATION_ERRORS":
      return flow(
        invitationUnknownError.set(false),
        invitationAlreadyClaimed.set(false),
        invitationInvalid.set(false)
      )(state);
    default:
      return state;
  }
};
