import React, { useEffect } from "react";
import { LoginCredentials } from "../../auth/login";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/text-input";
import { HmiLogo } from "../../components/hmi-logo";
import "./login-screen.css";
import { Button } from "../../components/button";
import { WithSession, hasActiveSession, contentOrLoading } from "../../util";
import { APP_ROUTES } from "../../router";
import { useHistory } from "react-router-dom";
import { EmailLoginErrors, ConfirmationErrors } from "../../store/state";

export type LoginScreenProps = {
  errors: EmailLoginErrors;
  confirmationErrors: ConfirmationErrors;
  loading: boolean;
  unconfirmed: boolean;
  submit: (creds: LoginCredentials) => void;
  clearResetEmail: () => void;
} & WithSession;

export function LoginScreen(props: LoginScreenProps) {
  const { unconfirmed, submit, errors, confirmationErrors, clearResetEmail } =
    props;
  const { t } = useTranslation();
  const history = useHistory();

  // Local state hooks
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    clearResetEmail();
  }, [clearResetEmail]);

  if (unconfirmed) {
    const confirmPath = APP_ROUTES.confirm.toPath();
    history.push(confirmPath);
  }

  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  const disabled = email === "" || password.length < 8;
  const maybeSubmit = disabled
    ? () => {}
    : () => submit({ email, password, type: "EMAIL" });

  const loginError =
    errors.invalidCredentials || confirmationErrors.invalidCredentials
      ? true
      : undefined;
  const otherError = errors.unknownError ? true : undefined;

  const helpText = loginError
    ? t("errors.invalidCredentials")
    : otherError
    ? t("errors.genericError")
    : undefined;

  const loginForm = (
    <form
      className="login-form"
      onSubmit={(e) => {
        e.preventDefault();
        maybeSubmit();
      }}
    >
      <HmiLogo />
      <div className="text-inputs-wrap">
        <TextInput
          buttonStyle={"BASE"}
          label={t("inputs.email")}
          value={email}
          onChange={setEmail}
          error={loginError || otherError}
          //helperText={helpText} -- commented out until we match styling to design for error handling
        />
        <TextInput
          buttonStyle={"PASSWORD"}
          label={t("inputs.password")}
          value={password}
          onChange={setPassword}
          error={loginError || otherError}
          helperText={helpText}
        />
      </div>
      <Button
        text={t("login.title")}
        disabled={disabled}
        onPress={maybeSubmit}
        buttonStyle="OPAQUE"
      />
      <Button
        text={t("login.forgotPassword")}
        onPress={() => {
          history.push(APP_ROUTES.resetEmail.toPath());
        }}
        buttonStyle="TEXT"
      />
    </form>
  );

  return contentOrLoading(loginForm, "login-form", props.loading);
}
