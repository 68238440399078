import { connect } from "../../store/connect";
import {
  session as sessionLens,
  loading as loadingLens,
  externalSurveySessions,
  cohortInfo,
} from "../../store/lenses";
import { HomeScreen } from "./home-screen";

export const HomeScreenContainer = connect(
  (state) => ({
    loading: loadingLens(state),
    session: sessionLens(state),
    cohortInfo: cohortInfo(state),
    externalSurveySessions: externalSurveySessions(state),
  }),
  (dispatch) => ({
    getExternalSurveySessions: () => {
      dispatch({ type: "GET_EXTERNAL_SURVEY_SESSIONS" });
    },
  })
)(HomeScreen);
