import { call } from "redux-saga/effects";
import { InvitationOps } from "..";
import {
  Action,
  RequestCheckInvitation,
  RequestConsumeInvitation,
} from "../../actions";
import { putAction, takeAction, CallReturnType } from "../../../util";

function* checkOrConsumeInvitation(
  ops: InvitationOps,
  action: RequestCheckInvitation | RequestConsumeInvitation
) {
  const operation =
    action.type === "REQUEST_CHECK_INVITATION" ? "check" : "consume";
  const responseType: Action["type"] =
    action.type === "REQUEST_CHECK_INVITATION"
      ? "CHECK_INVITATION_RESPONSE"
      : "CONSUME_INVITATION_RESPONSE";

  const response: CallReturnType<typeof ops.operate> = yield call(
    ops.operate,
    action.code,
    operation
  );

  yield putAction({ type: responseType, response });
}

export function* invitationWatcher(ops: InvitationOps) {
  while (true) {
    const action:
      | RequestCheckInvitation
      | RequestConsumeInvitation = yield takeAction([
      "REQUEST_CHECK_INVITATION",
      "REQUEST_CONSUME_INVITATION",
    ]);
    yield call(checkOrConsumeInvitation, ops, action);
  }
}
