import React from "react";
import "./hmi-logo.css";
import { connect } from "../store/connect";
import { isNil } from "lodash";
import { cohortInfoSignup } from "../store/lenses";
import "./cohort-logo.css";
import _ from "lodash";

export type CohortLogoProps = {
  uri?: string;
};

function CohortLogo(props: CohortLogoProps) {
  const imgSrc = isNil(props.uri)
    ? process.env.PUBLIC_URL + "/hmp-logo"
    : props.uri;

  return (
    <img
      className="cohort-logo"
      alt="Healthy Minds Innovations"
      src={densityAwareSource(imgSrc, "png")}
    />
  );
}

function densityAwareSource(baseURI: string, imageFormat: string) {
  const suffix = densitySuffix(safeDensity());
  //hard coding individual cohort image in for now
  if (baseURI === "individualsignup")
    return process.env.PUBLIC_URL + "/datasecurity.png";
  return `${baseURI}${suffix}.${imageFormat}`;
}

function safeDensity(): number {
  const pixelRatio = devicePixelRatio;
  const supportedPixelRatios = [1, 1.5, 2, 3, 3.5];
  if (supportedPixelRatios.indexOf(pixelRatio) > -1) {
    return pixelRatio;
  } else {
    const diffs = supportedPixelRatios.map((x) => Math.abs(x - pixelRatio));
    const indexOfClosestRatio = _.indexOf(diffs, _.min(diffs));
    return supportedPixelRatios[indexOfClosestRatio];
  }
}

function densitySuffix(density: number): string {
  return density === 1 ? "" : `@${density}x`;
}

export const CohortLogoContainer = connect(
  (state) => ({
    uri: cohortInfoSignup(state)?.uri,
  }),
  (_dispatch) => ({})
)(CohortLogo);
