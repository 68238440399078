import React, { useRef, useEffect, useState } from "react";
import { CohortInfo, UserInfo, ExternalSurveySession } from "../../store/state";
import {
  replaceT1Placeholder,
  generateT2SurveyURL,
  getSurveyWidgetInfo,
  hasBeen30DaysSinceT1Start,
} from "../utils";
import { T1Section } from "./t1-section";
import { T2Section } from "./t2-section";
import { CheckIcons } from "./check-icons";
import { operateForOptIn } from "../../api/zapier";
import { RUNNING_IN_DEV } from "../../environment";
import { updateChallengeOptIn } from "../../auth/session";

import "../widgets.css";
import "./survey.css";
import { reportError } from "../../api/bugsnag-logger";

type Props = {
  userInfo?: UserInfo;
  cohortInfo?: CohortInfo;
  externalSurveySessions?: ExternalSurveySession[];
};

export function SurveyWidget(props: Props): JSX.Element {
  const t1DivRef = useRef<HTMLInputElement | null>(null);
  const [dashLineHeight, setDashLineHeight] = useState<number>(0);
  const [optInBtnLoading, setOptInBtnLoading] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<string>("");
  const { externalSurveySessions, cohortInfo, userInfo } = props;
  const challengeOptIn = userInfo?.challengeOptIn;

  const widgetInfo = getSurveyWidgetInfo(externalSurveySessions ?? []);
  const hasCompletedT1Survey =
    !!widgetInfo.t1Results && widgetInfo.t1Results.length > 0;
  const hasCompletedT2Survey =
    !!widgetInfo.t2Results && widgetInfo.t2Results.length > 0;
  const hasBeen30DaysSinceT1 = !!widgetInfo.t1StartedAt
    ? hasBeen30DaysSinceT1Start(widgetInfo.t1StartedAt)
    : false;

  useEffect(() => {
    // Set dash line height to t1 div height
    if (t1DivRef && t1DivRef.current) {
      setDashLineHeight(t1DivRef.current.clientHeight);
    }
  }, [setDashLineHeight, externalSurveySessions, challengeOptIn, invalidEmail]);

  if (
    !userInfo ||
    !cohortInfo ||
    !userInfo.participantId ||
    !cohortInfo.t1SurveyURL
  ) {
    return <></>;
  }

  const t1SurveyLink = replaceT1Placeholder(
    cohortInfo.t1SurveyURL,
    userInfo.participantId,
    userInfo.email
  );

  const t2SurveyLink =
    cohortInfo.t2SurveyURL &&
    externalSurveySessions &&
    externalSurveySessions.length > 0
      ? generateT2SurveyURL(
          cohortInfo.t2SurveyURL,
          userInfo.participantId,
          userInfo.email,
          externalSurveySessions
        )
      : "";

  const splitAtFirstSpace = (str: string) => {
    const [first, ...rest] = str.split(" ");
    const last = rest.join(" ");
    return [first, last];
  };

  // Parse org name and invite code from t1 survey url
  const parseCustomFields = (t1SurveyURL: string) => {
    const [, ...rest] = t1SurveyURL.split("?");
    const customFields = rest.join("");
    const orgKey = "cf1=";
    const inviteCodeKey = "cf2=";
    let orgName: string | undefined = "";
    let inviteCode: string | undefined = "";

    if (customFields) {
      const splitFields = customFields.split("&");
      const org = splitFields.find((field) => field.includes(orgKey));
      orgName = org?.split(orgKey)[1];
      const code = splitFields.find((field) => field.includes(inviteCodeKey));
      inviteCode = decodeURIComponent(code?.split(inviteCodeKey)[1] ?? "");
    }
    return [orgName, inviteCode];
  };

  const handleOptIn = async () => {
    try {
      setOptInBtnLoading(true);
      const [firstName, lastName] = splitAtFirstSpace(userInfo.name);
      const [orgName, inviteCode] = parseCustomFields(t1SurveyLink);
      const res = await operateForOptIn({
        email: userInfo.email,
        firstName,
        lastName,
        orgName: orgName ?? "",
        inviteCode: inviteCode ?? "",
        isDev: RUNNING_IN_DEV,
      });

      if (res.success) {
        updateChallengeOptIn(true);
        userInfo.challengeOptIn = "true"; // Handle UI
      } else if (res.invalidEmail) {
        setInvalidEmail(userInfo.email);
      } else {
        reportError("Opting in failed with error: ", res?.error);
      }
      if (!res.invalidEmail) {
        setOptInBtnLoading(false);
      }
    } catch (err) {
      reportError("Opt in failed: ", err);
      setOptInBtnLoading(false);
    }
  };
  return (
    <div className="survey-widget widget-card">
      <CheckIcons
        t1Completed={hasCompletedT1Survey}
        t2Completed={hasCompletedT2Survey}
        dashLineHeight={dashLineHeight}
      />
      <div className="survey-widget-wrap">
        <T1Section
          t1Completed={hasCompletedT1Survey}
          been30Days={hasBeen30DaysSinceT1}
          t1DivRef={t1DivRef}
          t1SurveyLink={t1SurveyLink}
          challengeEnabled={cohortInfo?.challengeEnabled!}
          challengeOptedIn={userInfo?.challengeOptIn === "true" ? true : false}
          optIn={handleOptIn}
          optInBtnLoading={optInBtnLoading}
          invalidEmail={invalidEmail}
        />
        <T2Section
          t1Completed={hasCompletedT1Survey}
          t2Completed={hasCompletedT2Survey}
          been30Days={hasBeen30DaysSinceT1}
          t2SurveyLink={t2SurveyLink}
        />
      </div>
    </div>
  );
}
