import { take, call } from "redux-saga/effects";
import { AuthOps } from "..";
import { RequestLogin } from "../../actions";
import { CallReturnType, putAction } from "../../../util";
import { reportError } from "../../../api/bugsnag-logger";

function* handleLogin(ops: AuthOps["login"], action: RequestLogin) {
  const response: CallReturnType<typeof ops["doLogin"]> = yield call(
    ops.doLogin,
    action.creds
  );
  yield putAction({ type: "LOGIN_RESPONSE", response });
}

export function* loginWatcher(ops: AuthOps["login"]) {
  while (true) {
    const requestLogin = yield take("REQUEST_LOGIN");
    yield call(handleLogin, ops, requestLogin);
  }
}

function* handleLogout(ops: AuthOps["login"]) {
  try {
    yield call(ops.doLogout);
    yield putAction({
      type: "LOGOUT_RESPONSE",
      response: { status: "SUCCESS" },
    });
  } catch (e) {
    reportError("Error doing logout", e);
    yield putAction({
      type: "LOGOUT_RESPONSE",
      response: { status: "ERROR", reason: e.message },
    });
  }
}

export function* logoutWatcher(ops: AuthOps["login"]) {
  while (true) {
    yield take("REQUEST_LOGOUT");
    yield call(handleLogout, ops);
  }
}
