import { AuthOps } from "..";
import { takeAction, CallReturnType, putAction } from "../../../util";
import {
  RequestResetPassword,
  RequestResetPasswordSubmit,
} from "../../actions";
import { call } from "redux-saga/effects";
import { LoginCredentials } from "../../../auth/login";

function* handleResetEmail(
  ops: AuthOps["reset"],
  action: RequestResetPassword
) {
  const response: CallReturnType<typeof ops["forgotPassword"]> = yield call(
    ops.forgotPassword,
    action.email
  );
  yield putAction({ type: "RESET_PASSWORD_RESPONSE", response });
}

function* handleResetSubmit(
  ops: AuthOps["reset"],
  action: RequestResetPasswordSubmit
) {
  const { email, newPassword, code } = action;
  const response: CallReturnType<typeof ops["resetPassword"]> = yield call(
    ops.resetPassword,
    email,
    code,
    newPassword
  );
  yield putAction({ type: "RESET_PASSWORD_SUBMIT_RESPONSE", response });
  if (response.status === "RESET_PASSWORD_SUCCESS") {
    let creds: LoginCredentials = {
      type: "EMAIL",
      email: email,
      password: newPassword,
    };
    yield putAction({ type: "REQUEST_LOGIN", creds });
  }
}

export function* resetEmailWatcher(ops: AuthOps["reset"]) {
  while (true) {
    const requestReset = yield takeAction("REQUEST_RESET_PASSWORD");
    yield call(handleResetEmail, ops, requestReset);
  }
}

export function* resetSubmitWatcher(ops: AuthOps["reset"]) {
  while (true) {
    const requestResetSubmit = yield takeAction(
      "REQUEST_RESET_PASSWORD_SUBMIT"
    );
    yield call(handleResetSubmit, ops, requestResetSubmit);
  }
}
