import Amplify, { Auth } from "aws-amplify";
import { isNil } from "lodash";
import gql from "graphql-tag";
import * as GQLTypes from "@hminnovations/graphql-types";
import { userFragment } from "@hminnovations/graphql-operations/lib/fragments";
import { AWS_AMP_CONFIG } from "../environment";
import { mutate } from "./gql";
import { buildGqlClient } from "../util";

Amplify.configure({
  Auth: AWS_AMP_CONFIG,
});

export function getCredentials() {
  return Auth.currentUserCredentials();
}

export async function getCurrentCognitoId(): Promise<string> {
  const userInfo = await Auth.currentUserInfo();

  if (!isNil(userInfo) && !isNil(userInfo.id)) {
    return userInfo.id;
  }

  throw new Error(`Unable to retrieve user from Cognito, got ${userInfo}`);
}

interface CreateUserResult {
  createUserInfo: GQLTypes.User | null;
}

const CreateUserMutation = gql`
  mutation CreateUser($creds: UserItemCreds!, $input: UserInput!) {
    createUser(creds: $creds, input: $input) {
      ...UserFields
    }
  }
  ${userFragment}
`;

export const createUser = async (
  creds: GQLTypes.UserItemCreds,
  input: GQLTypes.UserInput
): Promise<GQLTypes.User | undefined> => {
  const client = await buildGqlClient();
  const res = await mutate<CreateUserResult, GQLTypes.CreateUserMutationArgs>(
    CreateUserMutation,
    { creds, input },
    client
  );

  return res && res.createUserInfo ? res.createUserInfo : undefined;
};
