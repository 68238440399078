import { AwsConfig } from ".";

export const prod: AwsConfig = {
  region: "us-east-1",
  gqlApi:
    "https://ajfi3pa73jg6zeh2dmhwwpvmga.appsync-api.us-east-1.amazonaws.com/graphql",
  identityPoolId: "us-east-1:3a307e85-369d-417e-8276-338b2b33aa85",
  userPoolId: "us-east-1_UWOHthBVG",
  userPoolWebClientId: "3kjt2839lkgs446o8odo7nks07",
  invitationHandlerLambda: "tf-prod-graphql-invitation-handler",
  zapierHandlerLambda: "tf-prod-zapier-handler",
};
