import { call, fork, select, takeEvery } from "redux-saga/effects";
import * as Signup from "../../auth/signup";
import * as Login from "../../auth/login";
import * as Session from "../../auth/session";
import * as Reset from "../../auth/reset-password";
import { loginWatcher, logoutWatcher } from "./auth/login";
import { signupWatcher } from "./auth/signup";
import { operateForInvitationCode } from "../../api/invitation";
import { invitationWatcher } from "./cohort/invitation";
import { loadExistingSession } from "./auth/session";
import { resetEmailWatcher, resetSubmitWatcher } from "./auth/reset-password";
import {
  confirmEmailWatcher,
  resendConfirmationWatcher,
} from "./auth/confirm-email";
import { getExternalSurveySession } from "../../api/externalSurveySession";
import { putAction } from "../../util";
import { session as sessionLens } from "../lenses";
import { AppSession } from "../state";
import { reportError } from "../../api/bugsnag-logger";

type AuthLoginOps = {
  doLogin: (creds: Login.LoginCredentials) => Promise<Login.AuthLoginResponse>;
  doLogout: () => Promise<any>;
};

type AuthResetOps = {
  forgotPassword: (username: string) => ReturnType<typeof Reset.forgotPassword>;
  resetPassword: (
    username: string,
    code: string,
    password: string
  ) => ReturnType<typeof Reset.resetPassword>;
};

type AuthSignupOps = {
  doSignup: (creds: Signup.SignupCredentials) => Promise<Signup.SignupResponse>;
  handleVerificationCode: (
    username: string,
    code: string,
    password: string
  ) => Promise<any>;
  resendVerificationCode: (username: string) => Promise<any>;
};

type AuthSessionOps = {
  getSession: () => ReturnType<typeof Session.getSession>;
};

export type AuthOps = {
  login: AuthLoginOps;
  reset: AuthResetOps;
  signup: AuthSignupOps;
  session: AuthSessionOps;
};

export type InvitationOps = {
  operate: (
    code: string,
    operation: "check" | "consume"
  ) => ReturnType<typeof operateForInvitationCode>;
};

function makeAuthOps(): AuthOps {
  return {
    login: Login,
    reset: Reset,
    signup: Signup,
    session: Session,
  };
}

function makeInvitationOps(): InvitationOps {
  return { operate: operateForInvitationCode };
}

export function* mainSaga() {
  const authOps = makeAuthOps();
  const invitationOps = makeInvitationOps();

  // Load extant session, if there is one
  yield fork(loadExistingSession, authOps.session);

  // All of the watchers!
  yield fork(resetEmailWatcher, authOps.reset);
  yield fork(resetSubmitWatcher, authOps.reset);
  yield fork(loginWatcher, authOps.login);
  yield fork(logoutWatcher, authOps.login);
  yield fork(signupWatcher, authOps.signup);
  yield fork(invitationWatcher, invitationOps);
  yield fork(confirmEmailWatcher, authOps.signup);
  yield fork(resendConfirmationWatcher, authOps.signup);

  yield fork(initialAuthenticatedQueries);

  console.log("Listening for requests...");
}

export function* initialAuthenticatedQueries() {
  yield takeEvery(
    "GET_EXTERNAL_SURVEY_SESSIONS",
    handleGetExternalSurveySession
  );
}

function* handleGetExternalSurveySession() {
  const s: AppSession | undefined = yield select(sessionLens);
  const userId = s?.auth?.awsCredentials?.identityId;
  const cohort = s?.cohort?.id;

  if (userId && cohort) {
    const xss = yield call(getExternalSurveySession, {
      userId,
      cohort,
    });
    yield putAction({
      type: "GOT_EXTERNAL_SURVEY_SESSIONS",
      externalSurveySessions: xss,
    });
  }
}

export function* rootSaga() {
  try {
    yield call(mainSaga);
  } catch (e) {
    reportError("Error in main saga", e);
  }
}
