import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { isNil } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export type TextInputProps = {
  buttonStyle: "BASE" | "PASSWORD" | "WHITE";
  label: string;
  value?: string;
  onChange: (newValue: string) => any;
  helperText?: string;
  error?: boolean;
};

const DefaultTextField = withStyles({
  root: {
    width: "345px",
    maxWidth: "100%",
    marginLeft: "8px",
    marginRight: "8px",
    "& label.Mui-focused": {
      color: "#56fdd2",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#56fdd2",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ff0048",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#56fdd2",
      },
    },
    "& .MuiFormLabel-root.Mui-error": {
      // Leave the label color intact
      color: "#ffffffb3",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#ff0048",
    },
  },
})(TextField);

const variantForButtonStyle = (
  buttonStyle: TextInputProps["buttonStyle"]
): TextFieldProps["variant"] => {
  if (buttonStyle === "WHITE") {
    return "filled";
  }

  return "standard";
};

export function TextInput(props: TextInputProps) {
  const { label, value, helperText, error } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  let textFieldProps: TextFieldProps = {
    size: "small",
    label,
    value,
    onChange: (e) => props.onChange(e.target.value),
    helperText,
    error,
  };

  const variant = variantForButtonStyle(props.buttonStyle);
  if (!isNil(variant)) {
    textFieldProps = {
      ...textFieldProps,
      variant,
    };
  }

  if (props.buttonStyle === "PASSWORD") {
    textFieldProps = {
      ...textFieldProps,
      type: showPassword ? "text" : "password",
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      },
    };
  }

  return <DefaultTextField {...textFieldProps} />;
}
