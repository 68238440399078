import { AsyncReturnType } from "../util";
import { getCredentials } from "../api/user";
import { ExternalSurveyScore } from "@hminnovations/graphql-types";

export type UrlParams = {
  assignParticipant?: boolean;
  participantId?: string;
  email?: string;
  name?: string;
  subCohort?: string;
};

export type AuthInfo = {
  identityId: string;
  awsCredentials: AsyncReturnType<typeof getCredentials>;
};

export type UserInfo = {
  email: string;
  name: string;
  participantId?: string;
  platforms?: string;
  challengeOptIn: string;
  subCohort?: string;
};

export type CohortInfo = {
  name: string;
  uri: string;
  id: string;
  path: string;
  t1SurveyURL?: string;
  t2SurveyURL?: string;
  challengeEnabled?: boolean;
  microsupportEnabled?: boolean;
};

export type RoleInfo = {
  id: string;
  title: string;
};

export type ExternalSurveySession = {
  id: string;
  score?: ExternalSurveyScore;
  startedAt: string;
  surveyId: string;
};

export type AppSession = {
  auth: AuthInfo;
  user: UserInfo;
  cohort: CohortInfo;
  role?: RoleInfo;
};

export type EmailLoginErrors = {
  invalidCredentials: boolean;
  unknownError: boolean;
};

export type InvitationErrors = {
  alreadyClaimed: boolean;
  invalidCode: boolean;
  unknownError: boolean;
};

export type ConfirmationErrors = {
  invalidCode: boolean;
  invalidCredentials: boolean;
  unknownError: boolean;
};

export type ResetPasswordErrors = {
  invalidCode: boolean;
  invalidEmail: boolean;
  unknownError: boolean;
  limitExceeded: boolean;
  invalidPassword: boolean;
};

export type SignupErrors = {
  unknownError: boolean;
  invalidPassword: boolean;
  invalidEmail: boolean;
  invalidName: boolean;
  invalidFirstName: boolean;
  invalidLastName: boolean;
  alreadyExists: boolean;
};

export type ErrorState = {
  signup: SignupErrors;
  login: EmailLoginErrors;
  invitation: InvitationErrors;
  resetpassword: ResetPasswordErrors;
  confirmation: ConfirmationErrors;
};

export type InvitationStatus = {
  code?: string;
  accepted: boolean;
};

export type EmailConfirmationStatus = {
  loading: boolean;
  accepted: boolean;
  unconfirmed: boolean;
};

export type SignupCredentials = {
  email: string;
  password: string;
};

export type SignupState = {
  invitation: InvitationStatus;
  confirmation: EmailConfirmationStatus;
  credentials?: SignupCredentials;
  cohort?: CohortInfo;
};

export type ResetPasswordEmailState = {
  email?: string;
  codeAccepted: boolean;
};

export type ResetPasswordState = {
  email?: string;
  codeAccepted: boolean;
};

export type AppState = {
  globalLoading: boolean;
  session?: AppSession;
  signup: SignupState;
  resetEmail: ResetPasswordEmailState;
  reset: ResetPasswordState;
  errors: ErrorState;
  urlParams: UrlParams;
  externalSurveySessions?: ExternalSurveySession[];
};

export function initialEmailLoginErrors(): EmailLoginErrors {
  return { invalidCredentials: false, unknownError: false };
}

export function initialInvitationErrors(): InvitationErrors {
  return { invalidCode: false, alreadyClaimed: false, unknownError: false };
}

export function initialConfirmationErrors(): ConfirmationErrors {
  return { invalidCode: false, invalidCredentials: false, unknownError: true };
}

export function initialResetPasswordErrors(): ResetPasswordErrors {
  return {
    invalidCode: false,
    invalidEmail: false,
    unknownError: false,
    limitExceeded: false,
    invalidPassword: false,
  };
}

export function initialSignupErrors(): SignupErrors {
  return {
    invalidEmail: false,
    invalidPassword: false,
    invalidName: false,
    unknownError: false,
    alreadyExists: false,
    invalidLastName: false,
    invalidFirstName: false,
  };
}

export function initialErrorState(): ErrorState {
  return {
    login: initialEmailLoginErrors(),
    signup: initialSignupErrors(),
    resetpassword: initialResetPasswordErrors(),
    invitation: initialInvitationErrors(),
    confirmation: initialConfirmationErrors(),
  };
}

export function initialUrlParams(): UrlParams {
  return {};
}

export function initialInvitationStatus(): InvitationStatus {
  return { accepted: false };
}

export function initialConfirmationStatus(): EmailConfirmationStatus {
  return {
    accepted: false,
    loading: false,
    unconfirmed: false,
  };
}

export function initialSignupState(): SignupState {
  return {
    invitation: initialInvitationStatus(),
    confirmation: initialConfirmationStatus(),
  };
}

export function initialResetPasswordEmailState(): ResetPasswordEmailState {
  return {
    codeAccepted: false,
  };
}

export function initialResetPasswordState(): ResetPasswordState {
  return {
    codeAccepted: false,
  };
}

export function initialAppState(): AppState {
  return {
    globalLoading: true,
    signup: initialSignupState(),
    resetEmail: initialResetPasswordEmailState(),
    reset: initialResetPasswordState(),
    errors: initialErrorState(),
    urlParams: initialUrlParams(),
  };
}
