export const es = {
  translation: {
    title: "Healthy Minds Program",
    invitationCode: {
      title: "Código de Invitación",
      submit: "Enviar",
    },
    login: {
      title: "Iniciar Sesión",
      already: "¿Ya tienes una cuenta?",
      forgotPassword: "¿Olvidaste tu contraseña?",
    },
    resetPassword: {
      instruction:
        "Recibirás un correo con un código para restablecer tu contraseña",
      accountEmail: "Escribe tu correo electrónico",
      success: "¡Registro exitoso!",
      codeSent: {
        part1: "Un código de contraseña fue enviado a:",
        part2: "Por favor escribe tu nueva contraseña:",
      },
    },
    logout: {
      title: "Cerrar sesión",
    },
    frontDoor: {
      signUp: "Regístrate",
      login: "Iniciar Sesión",
    },
    errors: {
      invalidEmailOrPassword: "Correo electrónico o código no válidos",
      invalidEmailAddress: "Correo electrónico invalido",
      invalidConfirmationCode: "Código de confirmación invalido",
      invalidCredentials:
        "Favor de ingresar un usuario y una contraseña válidos",
      genericError:
        "Algo salió mal. Por favor inténtalo de nuevo. Escribe a support@hminnovations.org si el problema persiste.",
      extantEmail: "La cuenta ya existe",
      invalidEmailZapier:
        "es una diección inválida de e-mail para el reto. Por favor escríbenos a support@hminnovations.org.",
      slowDown:
        "Alguien (probablemente tú) ha intentado restablecer la contraseña en varias ocasiones seguidas. " +
        "Para proteger tu cuenta, el restablecimiento de contraseña ha sido suspendido por un tiempo. " +
        "Por favor intenta más tarde.",
      pageNotFound:
        "La página que solicitaste no está disponible. Por favor escribe a support@hminnovations.org si necesitas asesoría.",
    },
    createAccount: {
      headerText:
        "Crear una cuenta le da acceso a la aplicación del Healthy Minds Program y le permite guardar su progreso.",
      submit: "Crear una cuenta",
      healthWarning:
        "Favor de tomar en cuenta que este programa no es un substituto para un  tratamiento de salud mental profesional, no realiza diagnósticos ni trata padecimientos. Si tienes dudas al respecto, consulta a tu proveedor de salud mental profesional antes de iniciar el programa.",
    },
    verifyEmail: {
      newCodeSent: "Se ha enviado un nuevo código",
      invalidCode: "Codigo invalido",
      almostThere: "¡Ya casi lo logras!",
      sentTo: "Fue enviado un código de confirmación de seis dígitos a:",
      confirmationCode: "Código de confirmación",
      resendCode: "Reenviar código",
      submit: "Enviar",
      newAccount: "Si su dirección de correo electrónico es incorrecta, ",
      newAccountLink: "cree una nueva cuenta",
      spamWarning: "¿No lo encuentras? Revisa tu carpeta de correo basura ",
      spamWarningEmail: "hello@hminnovations.org",
      needHelp: "¿Necesitas ayuda?",
    },
    verifySuccess: {
      headerText: "Correo electrónico confirmado",
      cta: "Descargue la aplicación del Healthy Minds Program e inicie sesión con sus nuevas credenciales.",
      appleStore: "Descargar en la App Store",
      googlePlay: "Descargar en Google Play",
    },
    inputs: {
      name: "Nombre completo",
      email: "Correo electrónico",
      emailConfirmation: "",
      password: "Contraseña",
      passwordNew: "Nueva contraseña",
      passwordHelp:
        "Al menos 8 caracteres que contengan un número y una letra minúscula (los espacios no están permitidos)",
      firstName: "Nombre",
      lastName: "Apellidos",
    },
    terms: {
      tos: "Acepto el acuerdo de ",
      tosLink: "términos de servicio",
      privacy: "He leído y entiendo las ",
      privacyLink: "políticas de privacidad",
    },
    widgets: {
      downloadTitle: "Descarga la aplicación",
      downloadDescription:
        "Ya has creado una cuenta. Tan sólo descarga la aplicación e inicia sesión.",
      downloadAppStore: "Descargar en la App Store",
      downloadPlayStore: "Descargar en Google Play",
      emailConfirmedTitle: "Correo electrónico confirmado",
      emailConfirmedDescription:
        "Este será el correo electrónico asociado a tu cuenta del Healthy Minds Program.",
      t1SurveyTitle: "Evaluación del bienestar",
      t1SurveyDescription:
        "Realiza tu primera evaluación de bienestar y luego comienza el reto de 30 días en la app Healthy Minds Program.",
      t1SurveyDescriptionCompleted:
        "¡Has concluido la evaluación bienestar inicial para el lugar de trabajo! Te hemos enviado los resultados por correo electrónico.",
      t1SurveyButtonText: "Realiza la evaluación",
      t2SurveyTitle: "Segunda evaluación",
      t2SurveyDescription:
        "Después de 30 días regresa aquí para llevar a cabo la segunda evaluación y ver qué tanto has cambiado.",
      t2SurveyDescriptionAfter30Days:
        "Han pasado 30 días desde tu última evaluación. Tómate un momento para notar cuánto has cambiado.",
      t2SurveyButtonText: "Realiza la segunda evaluación",
      refreshDescription:
        "Haz clic para actualizar si ya tomaste la evaluación",
      refreshButtonText: "Actualizar",
      optIntoChallengeTitle: "Regístrate para el reto de 30 días",
      optIntoChallengeDescription:
        "Comienza tu primer mes en la app con un email semanal de uno de nuestros facilitadores de Healthy Minds, en el que se incluyen consejos y videos para profundizar tu entendimiento del bienestar.",
      optIntoChallengeDescriptionCompleted:
        "¡Estás registrado para el reto de 30 días! Recibirás un recordatorio semanal por e-mail durante cutro semanas.",
      optIntoChallengeButtonText: "Regístrame",
    },
    deepLinkPage: {
      title:
        "Tan sólo podrás acceder al contenido de esta liga desde la app en tu dispositivo móvil",
      description:
        "Escanea el código QR con la cámara de tu dispositivo móvil para abrir o descargar la app.",
    },
  },
};
