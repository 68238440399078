import React from "react";
import "./hmi-logo.css";

export function HmiLogo() {
  return (
    <img
      className="hmp-logo"
      alt="Healthy Minds Innovations"
      src={process.env.PUBLIC_URL + "/hmp-logo.png"}
    />
  );
}
