import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { openInNewTab } from "../../util";
import downloadIcon from "../../assets/icon-download.svg";

import "../widgets.css";
import "./app-installation.css";

export function AppInstallationWidget(): JSX.Element {
  const { t } = useTranslation();

  const PLAY_STORE_URL =
    "https://play.google.com/store/apps/details?id=com.healthyminds&hl=en_US&gl=US";
  const APP_STORE_URL =
    "https://apps.apple.com/us/app/healthy-minds-program/id1326310617";

  return (
    <div className="app-installation-widget widget-card">
      <img className="icon-download" src={downloadIcon} alt="download-icon" />

      <div className="app-installation-widget-info">
        <span className="app-installation-title widget-title">
          {t("widgets.downloadTitle")}
        </span>
        <span className="app-installation-description widget-description">
          {t("widgets.downloadDescription")}
        </span>
        <div className="app-installation-links">
          <Button
            text={t("widgets.downloadAppStore")}
            onPress={() => openInNewTab(APP_STORE_URL)}
            buttonStyle="OPAQUE"
          />
          <Button
            text={t("widgets.downloadPlayStore")}
            onPress={() => openInNewTab(PLAY_STORE_URL)}
            buttonStyle="OUTLINE"
          />
        </div>
      </div>
    </div>
  );
}
