import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/button";
import { openInCurrentTab } from "../../../util";

type Props = {
  t1Completed: boolean;
  t2Completed: boolean;
  been30Days: boolean;
  t2SurveyLink: string;
};
export const T2Section = (props: Props) => {
  const { t1Completed, been30Days, t2Completed, t2SurveyLink } = props;
  const { t } = useTranslation();

  return (
    <div className="survey-widget-info-t2">
      <span className={`widget-title ${!been30Days ? "text-disabled" : ""}`}>
        {t("widgets.t2SurveyTitle")}
      </span>

      {!t2Completed && (
        <span
          className={`survey-widget-description widget-description ${
            !been30Days ? "text-disabled" : ""
          }`}
        >
          {been30Days
            ? t("widgets.t2SurveyDescriptionAfter30Days")
            : t("widgets.t2SurveyDescription")}
        </span>
      )}

      {t1Completed && !been30Days && !t2Completed && (
        <Button
          text={t("widgets.t2SurveyButtonText")}
          onPress={() => {}}
          buttonStyle="OUTLINE"
          disabled
        />
      )}

      {t1Completed && been30Days && !t2Completed && (
        <Button
          text={t("widgets.t2SurveyButtonText")}
          onPress={() => openInCurrentTab(t2SurveyLink)}
          buttonStyle="OPAQUE"
        />
      )}
    </div>
  );
};
