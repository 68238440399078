import React from "react";
import { InvitationCodeScreenContainer } from "./screens/invitation-code/invitation-code-screen-container";
import { SignupScreenContainer } from "./screens/signup/signup-screen-container";
import { LoginScreenContainer } from "./screens/login/login-screen-container";
import { HomeScreenContainer } from "./screens/home/home-screen-container";
import { LandingScreenContainer } from "./screens/landing/landing-screen-container";
import { ConfirmEmailScreenContainer } from "./screens/confirm-email/confirm-email-screen-container";
import { FrontDoorScreenContainer } from "./screens/front-door/front-door-screen-container";
import { ResetPasswordEmailScreenContainer } from "./screens/reset-password/reset-password-email-screen-container";
import { ResetPasswordScreenContainer } from "./screens/reset-password/reset-password-screen-container";
import { DeepLinkContainer } from "./screens/deep-link/deep-link-container";
import { ErrorScreenContainer } from "./screens/error/error-screen-container";

export const INVITE_ID = ":invitationCode";

export type AppRoute = {
  pathId: string;
  toPath: (id?: string) => string;
  component: JSX.Element;
};

const codeRoute: AppRoute = {
  pathId: `/code`,
  toPath: () => "/code",
  component: <InvitationCodeScreenContainer />,
};

const signupRoute: AppRoute = {
  pathId: "/signup",
  toPath: () => "/signup",
  component: <SignupScreenContainer />,
};

const loginRoute: AppRoute = {
  pathId: "/login",
  toPath: () => "/login",
  component: <LoginScreenContainer />,
};

const homeRoute: AppRoute = {
  pathId: "/home",
  toPath: () => "/home",
  component: <HomeScreenContainer />,
};

const landingRoute: AppRoute = {
  pathId: `/activate/${INVITE_ID}`,
  toPath: (inviteCode) => `/activate/${inviteCode || ""}`,
  component: <LandingScreenContainer />,
};

const confirmationRoute: AppRoute = {
  pathId: "/confirmation",
  toPath: () => "/confirmation",
  component: <ConfirmEmailScreenContainer />,
};

const frontDoorRoute: AppRoute = {
  pathId: "/",
  toPath: () => "/",
  component: <FrontDoorScreenContainer />,
};

const resetPasswordEmailRoute: AppRoute = {
  pathId: "/reset-password-email",
  toPath: () => "/reset-password-email",
  component: <ResetPasswordEmailScreenContainer />,
};

const resetPasswordRoute: AppRoute = {
  pathId: "/reset-password",
  toPath: () => "/reset-password",
  component: <ResetPasswordScreenContainer />,
};

const deepLinkRoute: AppRoute = {
  pathId: "/launch",
  toPath: () => "/launch",
  component: <DeepLinkContainer />,
};

const errorScreenRoute: AppRoute = {
  pathId: "/error",
  toPath: () => "/error",
  component: <ErrorScreenContainer />,
};

export const APP_ROUTES = {
  code: codeRoute,
  signup: signupRoute,
  login: loginRoute,
  home: homeRoute,
  landing: landingRoute,
  confirm: confirmationRoute,
  frontDoor: frontDoorRoute,
  resetEmail: resetPasswordEmailRoute,
  resetPassword: resetPasswordRoute,
  deepLink: deepLinkRoute,
  errorScreen: errorScreenRoute,
};
