import React from "react";
import { ResetPasswordErrors } from "../../store/state";
import { WithSession, hasActiveSession, contentOrLoading } from "../../util";
import { TextInput, TextInputProps } from "../../components/text-input";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isNil } from "lodash";
import { Button } from "../../components/button";
import { APP_ROUTES } from "../../router";
import { IconEmail } from "../../components/icons";
import "./reset-password-email-screen.css";

export type ResetPasswordEmailScreenProps = {
  loading: boolean;
  email?: string;
  errors: ResetPasswordErrors;
  submitEmail: (email: string) => void;
} & WithSession;

const emailDisabled = (email: string) => {
  return email === "" || isNil(email);
};

export function ResetPasswordEmailScreen(props: ResetPasswordEmailScreenProps) {
  const { errors, submitEmail, loading } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [resetEmail, setResetEmail] = React.useState("");

  if (hasActiveSession(props)) {
    const homePath = APP_ROUTES.home.toPath();
    history.push(homePath);
  }

  if (props.email) {
    const confirmPath = APP_ROUTES.resetPassword.toPath();
    history.push(confirmPath);
  }

  let inputProps: TextInputProps = {
    buttonStyle: "BASE",
    label: t("resetPassword.accountEmail"),
    value: resetEmail,
    onChange: setResetEmail,
    helperText: errors.limitExceeded
      ? t("errors.slowDown")
      : errors.invalidEmail
      ? t("errors.invalidEmailAddress")
      : errors.unknownError
      ? t("errors.genericError")
      : undefined,
    error:
      errors.invalidCode ||
      errors.invalidEmail ||
      errors.unknownError ||
      errors.limitExceeded,
  };

  const disabled = emailDisabled(resetEmail);
  const maybeSubmit = disabled
    ? () => {}
    : () => {
        submitEmail(resetEmail);
      };

  const resetForm = (
    <form className="reset-password-email">
      <IconEmail />
      <div className="reset-password-email-instruction">
        <span>{t("resetPassword.instruction")}</span>
      </div>
      <div className="text-inputs-wrap">
        <TextInput {...inputProps} />
      </div>
      <div className="reset-password-email-submit-btn-wrap">
        <Button
          buttonStyle="OPAQUE"
          disabled={disabled}
          text={t("verifyEmail.submit")}
          onPress={maybeSubmit}
        />
      </div>
    </form>
  );

  return contentOrLoading(resetForm, "reset-password-email", loading);
}
