import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { RUNNING_IN_DEV, BUGSNAG_API_KEY } from "../environment";
import { store } from "../store/create-store";
import { AppState } from "../store/state";
import { reportError } from "./bugsnag-logger";

export const initBugsnag = () => {
  if (RUNNING_IN_DEV) {
    console.log("Initializing bugsnag");
  }
  try {
    Bugsnag.start({
      apiKey: BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: RUNNING_IN_DEV ? "development" : "production",
      onError: (event) => {
        const redactedState = redact(store!.getState());
        event.addMetadata("appState", redactedState);
      },
    });
  } catch (e) {
    console.warn("Error while initializing bugsnag: ", e);
  }
};

export const setBugsnagUser = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (cognitoUser) {
      const userId = cognitoUser.username;
      const attributes = cognitoUser.attributes;
      const email = attributes && attributes.email;
      const cohortId = attributes && attributes["custom:cohortId"];
      Bugsnag.setUser(userId, cohortId, email);
    }
  } catch (e) {
    reportError("Error while getting cognito user: ", e);
  }
};

const redactedKeys = ["auth", "credentials"];

/**
 * Redact sensitive information from app state before sending it to bugsnag
 * @param a current app state
 */
export const redact = (a: AppState): any => {
  const newState = _.cloneDeep(a);
  redactedKeys.forEach((k) => {
    removeProperty(newState, k);
  });
  return newState;
};

/**
 * Remove nested properties from app state
 *
 * @param obj any nested object in state
 * @param match matching key in redactedKeys
 */
const removeProperty = (obj: any, match: string) => {
  const val = obj[match];
  if (val) {
    obj[match] = "[REDACTED]";
  }
  for (let v of Object.values(obj)) {
    if (v instanceof Object) {
      removeProperty(v, match);
    }
  }
};
