import React from "react";
import { useTranslation } from "react-i18next";
import { HmiLogo } from "../../components/hmi-logo";
import { IconOvals, IconQRCode, IconIphone } from "../../components/icons";
import "./deep-link-screen.css";

export type DeepLinkScreenProps = {};

export function DeepLinkScreen(props: DeepLinkScreenProps) {
  const { t } = useTranslation();
  return (
    <div className="deep-link-screen">
      <IconOvals />
      <div className="deep-link-screen-wrap">
        <div className="deep-link-screen-logo-wrap">
          <HmiLogo />
        </div>
        <IconIphone />
        <span className="deep-link-screen-title">
          {t("deepLinkPage.title")}
        </span>
        <span className="deep-link-screen-description">
          {t("deepLinkPage.description")}
        </span>
        <IconQRCode />
      </div>
    </div>
  );
}
