import { Auth } from "aws-amplify";
import { isNil } from "lodash";
import { AsyncReturnType, normalizeEmail } from "../util";
import { getCredentials } from "../api/user";
import { getUpstreamUserInfo, getUpstremCohortId } from "./user-info";
import { getIdentity, getPortalInfo } from "../api/identity";
import { AppSession } from "../store/state";
import { getCohortInfo } from "../api/cohort";
import { updatePlatforms } from "./session";
import { removeDataFromLocalStorage, KEY_T1_ACCESSED } from "../utils/storage";
import { createGQLIdentity } from "./signup";
import { setBugsnagUser } from "../api/bugsnag";
import { reportError, reportWarning } from "../api/bugsnag-logger";

type EmailLogin = {
  type: "EMAIL";
  email: string;
  password: string;
  skipUpdatePlatforms?: boolean;
};

export type LoginCredentials = EmailLogin;

type LogoutSuccess = {
  status: "SUCCESS";
};

type LogoutError = {
  status: "ERROR";
  reason: string;
};

export type AuthLogoutResponse = LogoutSuccess | LogoutError;

type AuthSuccess = {
  status: "SUCCESS";
  newSession: AppSession;
};

type UnconfirmedUserError = {
  type: "UNCONFIRMED";
  email: string;
  password: string;
};

type BadCredentialsError = {
  type: "BAD_CREDENTIALS";
  message: string;
};

type UnknownError = {
  type: "UNKNOWN";
  message: string;
};

type LoginError = UnconfirmedUserError | BadCredentialsError | UnknownError;

type AuthFailure = {
  status: "ERROR";
  error: LoginError;
};

export type AuthLoginResponse = AuthSuccess | AuthFailure;

async function doEmailLogin(creds: EmailLogin): Promise<AuthLoginResponse> {
  const safeEmail = normalizeEmail(creds.email);

  try {
    await Auth.signIn(safeEmail, creds.password);

    const cohortId = await getUpstremCohortId();

    let identity = await getIdentity({ email: safeEmail });
    let cohort = await getCohortInfo({ cohortId });

    if (identity === undefined || cohort === undefined) {
      const identityError: AuthFailure = {
        status: "ERROR",
        error: {
          type: "UNKNOWN",
          message: "Unable to load cohort and identity from backend",
        },
      };
      try {
        await createGQLIdentity(safeEmail);
        identity = await getIdentity({ email: safeEmail });
        cohort = await getCohortInfo({ cohortId });

        if (identity === undefined || cohort === undefined) {
          reportError(`Identity error when logging in`, {
            ...identityError,
            identity,
            cohort,
          });
          return identityError;
        }
      } catch (e) {
        reportError(`Identity error when logging in(2)`, {
          ...identityError,
          identity,
          cohort,
        });
        return identityError;
      }
    }

    const ampCreds = await getCredentials();
    const userInfo = await getUpstreamUserInfo();
    const role = await getPortalInfo({
      identityId: identity?.id,
      email: creds.email,
    });

    if (!creds.skipUpdatePlatforms) {
      await updatePlatforms();
    }

    await setBugsnagUser();
    return {
      status: "SUCCESS",
      newSession: {
        auth: {
          awsCredentials: ampCreds,
          identityId: identity.id,
        },
        user: userInfo,
        cohort,
        role: role?.role,
      },
    };
  } catch (e) {
    if (!isNil(e) && !isNil(e.code) && e.code === "UserNotConfirmedException") {
      reportWarning(`Attempted to sign in with unconfirmed account`, {
        email: safeEmail,
      });
      return {
        status: "ERROR",
        error: {
          type: "UNCONFIRMED",
          email: safeEmail,
          password: creds.password,
        },
      };
    }

    if (
      (!isNil(e) && !isNil(e.code) && e.code === "NotAuthorizedException") ||
      "UserNotFoundException"
    ) {
      reportWarning(`Signed in with bad credentials`, {
        email: safeEmail,
        password: "[REDACTED]",
      });
      return {
        status: "ERROR",
        error: {
          type: "BAD_CREDENTIALS",
          message: "Incorrect username or password.",
        },
      };
    }

    reportError("Unhandled auth exception", e);
    return {
      status: "ERROR",
      error: {
        type: "UNKNOWN",
        message: `Unhandled auth exception ${e}`,
      },
    };
  }
}

export function doLogout() {
  clearStorageData();
  return Auth.signOut();
}

export function doLogin(creds: LoginCredentials) {
  switch (creds.type) {
    case "EMAIL":
      return doEmailLogin(creds);
    default:
      throw new Error(`No auth handler for cred type ${creds.type}`);
  }
}

export function isUnconfirmed(res: AsyncReturnType<typeof doLogin>): boolean {
  return res.status === "ERROR" && res.error.type === "UNCONFIRMED";
}

const clearStorageData = () => {
  removeDataFromLocalStorage(KEY_T1_ACCESSED);
};
