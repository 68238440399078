import React from "react";
import { groupBy } from "lodash";
import { ExternalSurveySession } from "../store/state";
import { AppInstallationWidget } from "./app-installation/app-installation";
import { EmailConfirmedWidget } from "./email-confirmed/email-confirmed";
import { SurveyWidgetContainer } from "./survey/survey-container";
import { parseISO } from "date-fns";

export function getWidgetsForSession(
  externalSurveySessions: ExternalSurveySession[],
  hasT1SurveyURL: boolean
): JSX.Element[] {
  if (!hasT1SurveyURL) {
    return [
      <EmailConfirmedWidget key="email-confirmed-widget" />,
      <AppInstallationWidget key="app-installation-widget" />,
    ];
  }

  const surveyWidgetInfo = getSurveyWidgetInfo(externalSurveySessions)
  const hasCompletedT1Survey = !!surveyWidgetInfo.t1Results && surveyWidgetInfo.t1Results.length > 0;

  return [
    hasCompletedT1Survey ? (
      <AppInstallationWidget key="app-installation-widget" />
    ) : (
      <EmailConfirmedWidget key="email-confirmed-widget" />
    ),
    <SurveyWidgetContainer key="survey-widget" />,
  ];
}

export const replaceT1Placeholder = (
  t1SurveyURL: string,
  participantId: string,
  email: string
) => {
  // If you change these placeholders, you need to change it in the CLI too.
  const participantIdPlaceholder = "[P_ID]";
  const emailPlaceholder = "[EMAIL]";

  return t1SurveyURL
    .replace(participantIdPlaceholder, participantId)
    .replace(emailPlaceholder, email);
};

/**
 * Append ACIP scores from t1 survey to generate t2 survey url
 *
 * @param t1SurveyURL
 * @param externalSurveySessions
 */
export const generateT2SurveyURL = (
  t2SurveyURL: string,
  participantId: string,
  email: string,
  t1Results: ExternalSurveySession[]
) => {
  const placeholder: { [key: string]: string } = {
    awareness: "[A_SCORE]",
    connection: "[C_SCORE]",
    insight: "[I_SCORE]",
    purpose: "[P_SCORE]",
  };
  let generatedT2SurveyURL = replaceT1Placeholder(
    t2SurveyURL,
    participantId,
    email
  );

  t1Results.forEach((s) => {
    const category = s.score?.category;
    const percentile = s.score?.percentile;
    if (category && (!!percentile || percentile === 0)) {
      generatedT2SurveyURL = generatedT2SurveyURL.replace(
        placeholder[category],
        percentile.toString()
      );
    }
  });
  return generatedT2SurveyURL;
};

export type SurveyWidgetInfo = {
  t1Results?: ExternalSurveySession[],
  t1StartedAt?: string,
  t2Results?: ExternalSurveySession[],
}

export const getSurveyWidgetInfo = (
  externalSurveySessions: ExternalSurveySession[]
): SurveyWidgetInfo => {
  const groups = groupBy(externalSurveySessions, "startedAt");
  const sortedKeys = Object.keys(groups).sort();

  let widgetInfo: SurveyWidgetInfo = {
    t1Results: undefined,
    t1StartedAt: undefined,
    t2Results: undefined,
  };

  for(let i = 0; i < sortedKeys.length; i++) {
    const groupKey = sortedKeys[i];
    const group = groups[groupKey];


    const groupCategories = new Set(group.map((s) => s.score?.category));
    if(
      groupCategories.size === 4 &&
      groupCategories.has("awareness") &&
      groupCategories.has("connection") &&
      groupCategories.has("insight") &&
      groupCategories.has("purpose")
    ) {
      if (!widgetInfo.t1StartedAt) {
        widgetInfo.t1Results = group;
        widgetInfo.t1StartedAt = groupKey;
      } else if (dateHasBeen30DaysSinceT1Start(widgetInfo.t1StartedAt, groupKey)) {
        widgetInfo.t2Results = group;
        break;
      }
    }
  }

  return widgetInfo;
}

export const hasBeen30DaysSinceT1Start = (t1StartedDate: string): boolean => {
  return dateHasBeen30DaysSinceT1Start(t1StartedDate, new Date().toISOString());
};

const dateHasBeen30DaysSinceT1Start = (t1StartedDate: string, compDate: string) => {
  const t1Date = parseISO(t1StartedDate);
  const parsedCompDate = parseISO(compDate);
  const t2AvailableDate = new Date(t1Date.getTime() + 30*24*60*60*1000);
  const t2AvailableAtMidnight = new Date(t2AvailableDate.setHours(0,0,0,0));

  return parsedCompDate.getTime() > t2AvailableAtMidnight.getTime();
}
