import React from "react";
import { contentOrLoading, WithSession, hasActiveSession } from "../../util";
import { useHistory } from "react-router-dom";
import { HmiLogo } from "../../components/hmi-logo";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/button";
import { APP_ROUTES } from "../../router";
import "./front-door-screen.css";

export type FrontDoorScreenProps = {
  unconfirmed: boolean;
  loading: boolean;
} & WithSession;

export function FrontDoorScreen(props: FrontDoorScreenProps) {
  const { loading } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const code = APP_ROUTES.code.toPath();
  const home = APP_ROUTES.home.toPath();
  const login = APP_ROUTES.login.toPath();

  if (hasActiveSession(props)) {
    history.push(home);
  }

  if (props.unconfirmed) {
    const confirmPath = APP_ROUTES.confirm.toPath();
    history.push(confirmPath);
  }

  const frontDoor = (
    <div className="front-door">
      <HmiLogo />
      <div className="front-door-buttons">
        <Button
          text={t("frontDoor.signUp")}
          onPress={() => history.push(code)}
          buttonStyle="OPAQUE"
        />
        <Button
          text={t("frontDoor.login")}
          onPress={() => history.push(login)}
          buttonStyle="TEXT"
        />
      </div>
    </div>
  );

  return contentOrLoading(frontDoor, "front-door", loading);
}
