import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WithSession, contentOrLoading } from "../../util";
import "./error-screen.css";
import { HmiLogo } from "../../components/hmi-logo";

export type ErrorScreenProps = {
  loading: boolean;
  clearInvitationErrors: () => void;
} & WithSession;

export const ErrorScreen = (props: ErrorScreenProps) => {
  const { loading, clearInvitationErrors } = props;
  const { t } = useTranslation();
  const pageNotFoundMessage = t("errors.pageNotFound");

  useEffect(() => {
    clearInvitationErrors();
  }, [clearInvitationErrors]);

  const errorScreen = (
    <div className="error-screen-container">
      <HmiLogo />
      <p className="error-message">{pageNotFoundMessage}</p>
    </div>
  );
  return contentOrLoading(errorScreen, "error-screen-container", loading);
};
